import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {
    AutocompleteInput,
    ReferenceInput,
    required,
    ImageInput,
    TabbedShowLayout,
    Tab,
    ReferenceManyField,
    Datagrid,
    TextField,
    NumberField,
    ShowButton,
    EditButton,
    FileInput
} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import {parse} from "query-string";
import AddAttributeValueButton from "./addAttributeValueButton";
import CustomVideoField from "../components/fields/CustomVideoField";
import RichTextInput from "ra-input-rich-text";

export const ComparableItemList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source={"title"}/>
        <FieldGuesser source={"subtitle"}/>
        <FieldGuesser source={"uid"}/>
        <FieldGuesser source={"position"}/>
        <FieldGuesser source={"price"}/>
    </ListGuesser>
);

export const ComparableItemCreate = props => {
    let {comparatorConfig: comparatorConfigUri} = parse(props.location.search);
    const encodedComparatorConfigUri = encodeURIComponent(comparatorConfigUri);
    const redirect = comparatorConfigUri ? `/comparator_configs/${encodedComparatorConfigUri}/items` : false;

    return (
        <CreateGuesser {...props} redirect={redirect} initialValues={{comparatorConfig: comparatorConfigUri}}>
            <InputGuesser source={"title"} validate={[required()]}/>
            <InputGuesser source={"subtitle"}/>
            <InputGuesser source={"uid"}/>
            <InputGuesser source={"position"}/>
            <InputGuesser source={"price"} validate={[required()]}/>
            <RichTextInput source="body"/>
            <ImageInput source="image" label="Image" accept="image/*"
                        placeholder={<p>Drop your file here</p>}>
                <CustomImageField source="contentUrl"/>
            </ImageInput>

            <ImageInput source="backgroundImage" label="Background Image" accept="image/*"
                        placeholder={<p>Drop your file here</p>}>
                <CustomImageField source="contentUrl"/>
            </ImageInput>

            <ImageInput source="singleBackgroundImage" label="Single Background Image" accept="image/*"
                        placeholder={<p>Drop your file here</p>}>
                <CustomImageField source="contentUrl"/>
            </ImageInput>

            <FileInput source="video" label="Video" accept="video/*"
                       placeholder={<p>Drop your file here</p>}>
                <CustomVideoField source="contentUrl"/>
            </FileInput>

            <InputGuesser source={"videoFullScreen"}/>

            <ReferenceInput
                allowEmpty={true}
                source="comparatorConfig"
                reference="comparator_configs"
                label="ComparatorConfig"
                filterToQuery={searchText => ({name: searchText})}
                validate={[required()]}
            >
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>

            <ReferenceInput
                allowEmpty={true}
                source="product"
                reference="products"
                label="Product"
                filterToQuery={searchText => ({name: searchText})}
            >
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>

            <ReferenceInput
                allowEmpty={true}
                source="productVersion"
                reference="product_versions"
                label="Product version"
                filterToQuery={searchText => ({name: searchText})}
            >
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>

            <ReferenceInput
                allowEmpty={true}
                source="project"
                reference="projects"
                label="Project"
                filterToQuery={searchText => ({name: searchText})}
                validate={[required()]}
            >
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
        </CreateGuesser>
    );
}

const editRedirect = (basePath, id, data) => {
    console.log('basePath, id, data', basePath, id, data);
    return `/comparator_configs/${encodeURIComponent(data.comparatorConfig)}/items`
};

export const ComparableItemEdit = props => (
    <EditGuesser {...props} redirect={editRedirect}>
        <TabbedShowLayout fullWidth={true}>
            <Tab label="Summary">
                <InputGuesser source={"title"} validate={[required()]}/>
                <InputGuesser source={"subtitle"}/>
                <InputGuesser source={"uid"}/>
                <InputGuesser source={"position"}/>
                <InputGuesser source={"price"} validate={[required()]}/>
                <RichTextInput source="body"/>
                <ImageInput source="image" label="Image" accept="image/*"
                            placeholder={<p>Drop your file here</p>}>
                    <CustomImageField source="contentUrl"/>
                </ImageInput>

                <ImageInput source="backgroundImage" label="Background Image" accept="image/*"
                            placeholder={<p>Drop your file here</p>}>
                    <CustomImageField source="contentUrl"/>
                </ImageInput>

                <ImageInput source="singleBackgroundImage" label="Single Background Image" accept="image/*"
                            placeholder={<p>Drop your file here</p>}>
                    <CustomImageField source="contentUrl"/>
                </ImageInput>

                <FileInput source="video" label="Video" accept="video/*"
                           placeholder={<p>Drop your file here</p>}>
                    <CustomVideoField source="contentUrl"/>
                </FileInput>

                <InputGuesser source={"videoFullScreen"}/>

                <ReferenceInput
                    allowEmpty={true}
                    source="comparatorConfig"
                    reference="comparator_configs"
                    label="ComparatorConfig"
                    filterToQuery={searchText => ({name: searchText})}
                    validate={[required()]}
                >
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>

                <ReferenceInput
                    allowEmpty={true}
                    source="product"
                    reference="products"
                    label="Product"
                    filterToQuery={searchText => ({name: searchText})}
                >
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>

                <ReferenceInput
                    allowEmpty={true}
                    source="productVersion"
                    reference="product_versions"
                    label="Product version"
                    filterToQuery={searchText => ({name: searchText})}
                >
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>

                <ReferenceInput
                    allowEmpty={true}
                    source="project"
                    reference="projects"
                    label="Project"
                    filterToQuery={searchText => ({name: searchText})}
                    validate={[required()]}
                >
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
            </Tab>
            <Tab label="Attribute Values" path="attribute_values" fullWidth>
                <ReferenceManyField
                    addLabel={false}
                    reference="attribute_values"
                    target="comparableItem"
                    sort={{field: 'attribute.position', order: 'ASC'}}>
                    <Datagrid>
                        <TextField source="attribute.name" label="Attribute name"/>
                        <NumberField source="attribute.position" label="Attribute position"/>
                        <TextField source="value"/>
                        <ShowButton/>
                        <EditButton/>
                    </Datagrid>
                </ReferenceManyField>
                <AddAttributeValueButton/>
            </Tab>
        </TabbedShowLayout>
    </EditGuesser>
);
