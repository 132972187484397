import {CreateGuesser, EditGuesser, FieldGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {
  ReferenceInput,
  AutocompleteInput,
  required,
  SelectInput
} from "react-admin";

export const ZohoTagConfigList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"actionType"}/>
    <FieldGuesser source={"tag"}/>
  </ListGuesser>
);

export const ZohoTagConfigCreate = props => (
  <CreateGuesser {...props}>
    <SelectInput source="actionType" choices={[
      {id: 'activate_action', name: 'Activate Action'},
      {id: 'compare_action', name: 'Compare Action'},
      {id: 'check_in_action', name: 'Check In Action'},
      {id: 'check_out_action', name: 'Check Out Action'},
      {id: 'download_action', name: 'Download Action'},
      {id: 'receive_via_email_action', name: 'Receive Via Email Action'},
      {id: 'retrieve_action', name: 'Retrieve Action'},
      {id: 'share_action', name: 'Share Action'},
      {id: 'test_drive_request_action', name: 'Test Drive Request Action'},
    ]} />

    <SelectInput source="tag" choices={[
      {id: 'lead_cold', name: 'Lead froid'},
      {id: 'lead_warm', name: 'Lead tiede'},
      {id: 'lead_hot', name: 'Lead Chaud'},
    ]}/>

    <ReferenceInput
      allowEmpty={true}
      source="application"
      reference="applications"
      label="Application"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </CreateGuesser>
);

export const ZohoTagConfigEdit = props => (
  <EditGuesser {...props}>
    <SelectInput source="actionType" choices={[
      {id: 'activate_action', name: 'Activate Action'},
      {id: 'compare_action', name: 'Compare Action'},
      {id: 'check_in_action', name: 'Check In Action'},
      {id: 'check_out_action', name: 'Check Out Action'},
      {id: 'download_action', name: 'Download Action'},
      {id: 'receive_via_email_action', name: 'Receive Via Email Action'},
      {id: 'retrieve_action', name: 'Retrieve Action'},
      {id: 'share_action', name: 'Share Action'},
      {id: 'test_drive_request_action', name: 'Test Drive Request Action'},
    ]} />

    <SelectInput source="tag" choices={[
      {id: 'lead_cold', name: 'Lead froid'},
      {id: 'lead_warm', name: 'Lead tiede'},
      {id: 'lead_hot', name: 'Lead Chaud'},
    ]}/>

    <ReferenceInput
      allowEmpty={true}
      source="application"
      reference="applications"
      label="Application"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </EditGuesser>
);
