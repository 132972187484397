import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {
    AutocompleteInput,
    ReferenceInput,
    required,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ImageInput,
    FileInput
} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import RichTextInput from "ra-input-rich-text";
import CustomVideoField from "../components/fields/CustomVideoField";

export const QuizConfigList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source={"name"}/>
    </ListGuesser>
);

export const QuizConfigCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"name"} validate={[required()]}/>

        <InputGuesser source={"randomizeQuestionsEnabled"}/>
        <InputGuesser source={"maxQuestions"}/>

        <ImageInput source="introButtonImage" label="intro Button Image" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="introBackgroundImage" label="intro Background Image" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source="introButtonLabel"/>

        <ImageInput source="headerBackgroundImage" label="Header Background Image" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="scanBackground" label="Header Scan Image" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <FileInput source="scanVideo" label="Scan Video" accept="video/*"
                   placeholder={<p>Drop your file here</p>}>
            <CustomVideoField source="contentUrl"/>
        </FileInput>

        <InputGuesser source="questionGuideText"/>

        <ImageInput source="choiceIcon" label="Choice Icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="selectedChoiceIcon" label="Selected Choice Icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="correctChoiceIcon" label="Correct Choice Icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="incorrectChoiceIcon" label="Incorrect Choice Icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="correctAnswerIcon" label="Correct Answer Icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>


        <RichTextInput source="correctAnswerTitle"
                       toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>
        <RichTextInput source="correctAnswerBody"
                       toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>
        <InputGuesser source={"correctAnswerColor"}/>
        <ImageInput source="wrongAnswerIcon" label="Wrong Answer Icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source="nextCategoryButtonLabel"/>

        <RichTextInput source="wrongAnswerTitle"
                       toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>
        <RichTextInput source="wrongAnswerBody"
                       toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>
        <InputGuesser source={"wrongAnswerColor"}/>

        <ImageInput source="completeIcon" label="Complete Icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <RichTextInput source="completeTitle"
                       toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>
        <RichTextInput source="completeBody"
                       toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>

        <InputGuesser source={"cssStyle"} multiline fullWidth={true}/>

        <ReferenceInput
            allowEmpty={true}
            source="quiz"
            reference="quizzes"
            label="Quiz"
            filterToQuery={searchText => ({name: searchText})}
        >
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>

        <ReferenceArrayInput
            source="devices"
            reference="devices"
            label="Devices"
            filterToQuery={searchText => ({name: searchText})}
            filter={{'application.slug': 'quiz'}}
        >
            <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput>

        <ReferenceInput
            allowEmpty={true}
            source="project"
            reference="projects"
            label="Project"
            filterToQuery={searchText => ({name: searchText})}
            validate={[required()]}
        >
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
    </CreateGuesser>
);
export const QuizConfigEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"name"} validate={[required()]}/>

        <InputGuesser source={"randomizeQuestionsEnabled"}/>
        <InputGuesser source={"maxQuestions"}/>

        <ImageInput source="introButtonImage" label="intro Button Image" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="introBackgroundImage" label="intro Background Image" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source="introButtonLabel"/>

        <ImageInput source="headerBackgroundImage" label="Header Background Image" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="scanBackground" label="Header Scan Image" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <FileInput source="scanVideo" label="Scan Video" accept="video/*"
                   placeholder={<p>Drop your file here</p>}>
            <CustomVideoField source="contentUrl"/>
        </FileInput>

        <InputGuesser source="questionGuideText"/>

        <ImageInput source="choiceIcon" label="Choice Icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="selectedChoiceIcon" label="Selected Choice Icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="correctChoiceIcon" label="Correct Choice Icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="incorrectChoiceIcon" label="Incorrect Choice Icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="correctAnswerIcon" label="Correct Answer Icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>


        <RichTextInput source="correctAnswerTitle"
                       toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>
        <RichTextInput source="correctAnswerBody"
                       toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>
        <InputGuesser source={"correctAnswerColor"}/>
        <ImageInput source="wrongAnswerIcon" label="Wrong Answer Icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source="nextCategoryButtonLabel"/>

        <RichTextInput source="wrongAnswerTitle"
                       toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>
        <RichTextInput source="wrongAnswerBody"
                       toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>
        <InputGuesser source={"wrongAnswerColor"}/>

        <ImageInput source="completeIcon" label="Complete Icon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <RichTextInput source="completeTitle"
                       toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>
        <RichTextInput source="completeBody"
                       toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>

        <InputGuesser source={"cssStyle"} multiline fullWidth={true}/>

        <ReferenceInput
            allowEmpty={true}
            source="quiz"
            reference="quizzes"
            label="Quiz"
            filterToQuery={searchText => ({name: searchText})}
        >
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>

        <ReferenceArrayInput
            source="devices"
            reference="devices"
            label="Devices"
            filterToQuery={searchText => ({name: searchText})}
            filter={{'application.slug': 'quiz'}}
        >
            <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput>

        <ReferenceInput
            allowEmpty={true}
            source="project"
            reference="projects"
            label="Project"
            filterToQuery={searchText => ({name: searchText})}
            validate={[required()]}
        >
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
    </EditGuesser>
);
