import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  ImageInput,
  required,
  SelectInput,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import AddConfigurationItemButton from "./addConfigurationItemButton";

export const BulletTimeConfigList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"numberOfPhotos"}/>
    <FieldGuesser source={"brandingEnabled"}/>
    <FieldGuesser source={"watermarkPosition"}/>
  </ListGuesser>
);

export const BulletTimeConfigCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"}/>
    <InputGuesser source={"brandingEnabled"}/>
    <InputGuesser source={"numberOfPhotos"} validate={[required()]}/>
    <SelectInput source="watermarkPosition" choices={[
      {id: 'top_left', name: 'Top left'},
      {id: 'top_right', name: 'Top right'},
      {id: 'bottom_left', name: 'Bottom left'},
      {id: 'bottom_right', name: 'Bottom right'},
    ]}
    />

    <ImageInput source="brandingLogo" label="BrandingLogo" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <ReferenceArrayInput
      source="devices"
      reference="devices"
      label="Devices"
      filterToQuery={searchText => ({name: searchText})}
      filter={{ 'application.slug': 'bullet-time'}}
    >
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>

    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </CreateGuesser>
);
export const BulletTimeConfigEdit = props => (
  <EditGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source={"name"}/>
        <InputGuesser source={"brandingEnabled"}/>
        <InputGuesser source={"numberOfPhotos"} validate={[required()]}/>
        <SelectInput source="watermarkPosition" choices={[
          {id: 'top_left', name: 'Top left'},
          {id: 'top_right', name: 'Top right'},
          {id: 'bottom_left', name: 'Bottom left'},
          {id: 'bottom_right', name: 'Bottom right'},
        ]}
        />

        <ImageInput source="brandingLogo" label="BrandingLogo" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ReferenceArrayInput
          source="devices"
          reference="devices"
          label="Devices"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
          filter={{ 'application.slug': 'bullet-time'}}
        >
          <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput>

        <ReferenceInput
          allowEmpty={true}
          source="project"
          reference="projects"
          label="Project"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      </Tab>
      <Tab label="Configurations" path="configurations">
        <ReferenceManyField
          addLabel={false}
          reference="configuration_item_values"
          target="bulletTimeConfig">
          <Datagrid>
            <TextField source="name"/>
            <TextField source="type"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddConfigurationItemButton context={'bulletTimeConfig'}/>
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
