import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {
  ReferenceArrayInput, ReferenceInput, AutocompleteInput, AutocompleteArrayInput,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  NumberField,
  FormDataConsumer
} from "react-admin";

import AddFormItemButton from './addFormItemButton';
import AddFormStepButton from './addFormStepButton';
import RichTextInput from "ra-input-rich-text";

export const FormList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"}/>
  </ListGuesser>
);

export const FormCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"}/>
    <InputGuesser source={"title"}/>
    <InputGuesser source={"subTitle"}/>
    <InputGuesser source={"isMultiStepForm"}/>
    <InputGuesser source={"submitButtonLabel"}/>
    <InputGuesser source={"legalNoticesEnabled"}/>
    <InputGuesser source={"legalNoticesLabel"}/>
    <RichTextInput source="legalNoticesContent" toolbar={[[{ 'header': [1, 2, 3, 4, 5, 6, false] }], ['bold', 'italic', 'underline', 'link', 'clean'], [{ 'direction': 'rtl' }],]}/>
    <ReferenceArrayInput
      source="checkInConfigs"
      reference="check_in_configs"
      label="CheckInConfigs"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>
    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </CreateGuesser>
);

export const FormEdit = props => (
  <EditGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source={"name"}/>
        <InputGuesser source={"title"}/>
        <InputGuesser source={"subTitle"}/>
        <InputGuesser source={"isMultiStepForm"}/>
        <InputGuesser source={"submitButtonLabel"}/>
        <InputGuesser source={"legalNoticesEnabled"}/>
        <InputGuesser source={"legalNoticesLabel"}/>
        <RichTextInput source="legalNoticesContent" toolbar={[[{ 'header': [1, 2, 3, 4, 5, 6, false] }], ['bold', 'italic', 'underline', 'link', 'clean'], [{ 'direction': 'rtl' }],]}/>
        <ReferenceArrayInput
          source="checkInConfigs"
          reference="check_in_configs"
          label="CheckInConfigs"
          filterToQuery={searchText => ({name: searchText})}
        >
          <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput>
        <ReferenceInput
          allowEmpty={true}
          source="project"
          reference="projects"
          label="Project"
          filterToQuery={searchText => ({name: searchText})}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      </Tab>
      <FormDataConsumer>
        {({formData, ...rest}) => !formData.isMultiStepForm &&
          <Tab label="Form Items" path="items" {...rest}>
            <ReferenceManyField
              addLabel={false}
              reference="form_items"
              target="form">
              <Datagrid>
                <TextField source="label"/>
                <TextField source="name"/>
                <TextField source="type"/>
                <FieldGuesser source="required"/>
                <ShowButton/>
                <EditButton/>
              </Datagrid>
            </ReferenceManyField>
            <AddFormItemButton context={'form'}/>
          </Tab>
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({formData, ...rest}) => formData.isMultiStepForm &&
          <Tab label="Form Steps" path="steps" {...rest}>
            <ReferenceManyField
              addLabel={false}
              reference="form_steps"
              target="form"
            >
              <Datagrid>
                <TextField source="title"/>
                <TextField source="subtitle"/>
                <NumberField source="order"/>
                <ShowButton/>
                <EditButton/>
              </Datagrid>
            </ReferenceManyField>
            <AddFormStepButton/>
          </Tab>
        }
      </FormDataConsumer>
    </TabbedShowLayout>
  </EditGuesser>
);
