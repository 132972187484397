import * as React from 'react';

const useFetch = (url) => {
    const [data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    React.useEffect(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/${url}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                setError(response.statusText);
            }
        }).then(data => {
            setData(data)
        }
        ).catch(error => {
            setError(error)
        }
        )




    }, [url]);
    return { data, loading, error };
}

export default useFetch;