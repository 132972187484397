export async function handleDeviceResource(params) {
  const data = params.data;
  if (typeof params.data.type !== 'undefined' && params.data.type !== null) {
    data.type = typeof params.data.type !== 'string' ? params.data.type['@id'] : params.data.type;
  }
  if (typeof params.data.application !== 'undefined' && params.data.application !== null) {
    data.application = typeof params.data.application !== 'string' ? params.data.application['@id'] : params.data.application;
  }
  return data;
}
