import {CreateGuesser, EditGuesser, InputGuesser, ShowGuesser} from "@api-platform/admin";
import React from "react";
import {TextInput, ReferenceInput, AutocompleteInput, downloadCSV} from "react-admin";
import jsonExport from 'jsonexport/dist';
import {ListGuesser, FieldGuesser} from "@api-platform/admin";
import {Fragment} from 'react';
import {BulkDeleteButton, usePermissions} from 'react-admin';


import {Card as MuiCard, CardContent, withStyles} from '@material-ui/core';

import {LatestFilter} from '../filters/datefilters';
import isGranted from "../security";

const Card = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1, // display on the left rather than on the right of the list
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const FilterSidebar = () => (
  <Card>
    <CardContent>
      <LatestFilter/>
    </CardContent>
  </Card>
);

const PostBulkActionButtons = props => (
  <Fragment>
    {/* default bulk delete action */}
    <BulkDeleteButton {...props} />
  </Fragment>
);

const exporter = people => {
  const peopleForExport = people.map(person => {
    return {
      id: person.originId,
      email: person.email,
      fullName: person.fullName,
      phone: person.phone,
    }
  });
  jsonExport(peopleForExport, {
    headers: ['id', 'email', 'fullName', 'phone'] // order fields in the export
  }, (err, csv) => {
    downloadCSV(csv, 'people'); // download as 'people.csv` file
  });
};

export const PersonList = props => {
  const {loaded, permissions} = usePermissions();

  return (
    <ListGuesser {...props} exporter={exporter} aside={<FilterSidebar/>} bulkActionButtons={loaded === true && isGranted('ROLE_ADMIN', permissions) ?
      <PostBulkActionButtons/> : false}>
      <FieldGuesser source="uid"/>
      <FieldGuesser source="email"/>
      <FieldGuesser source="fullName"/>
      <FieldGuesser source="phone"/>
      <FieldGuesser source="company"/>
      <FieldGuesser source="job"/>
      <FieldGuesser source="industry"/>
    </ListGuesser>
  );
}

export const PersonShow = props => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"uid"} addLabel={true} />
    <FieldGuesser source={"email"} addLabel={true} />
    <FieldGuesser source={"firstName"} addLabel={true} />
    <FieldGuesser source={"lastName"} addLabel={true} />
    <FieldGuesser source={"fullName"} addLabel={true} />
    <FieldGuesser source={"phone"} addLabel={true} />
    <FieldGuesser source={"company"} addLabel={true} />
    <FieldGuesser source={"job"} addLabel={true} />
    <FieldGuesser source={"industry"} addLabel={true} />
    <FieldGuesser source={"city"} addLabel={true} />
    <FieldGuesser source={"createdAt"} addLabel={true} showTime={true} />
  </ShowGuesser>
);

export const PersonCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"uid"}/>
    <InputGuesser source={"email"}/>
    <InputGuesser source={"firstName"}/>
    <InputGuesser source={"lastName"}/>
    <InputGuesser source={"fullName"}/>
    <InputGuesser source={"phone"}/>
    <InputGuesser source={"company"}/>
    <InputGuesser source={"job"}/>
    <InputGuesser source={"industry"}/>
    <InputGuesser source={"city"}/>
    <InputGuesser source={"profile"}/>
    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </CreateGuesser>
);

export const PersonEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source={"uid"}/>
    <InputGuesser source={"email"}/>
    <InputGuesser source={"firstName"}/>
    <InputGuesser source={"lastName"}/>
    <InputGuesser source={"fullName"}/>
    <InputGuesser source={"phone"}/>
    <InputGuesser source={"company"}/>
    <InputGuesser source={"job"}/>
    <InputGuesser source={"industry"}/>
    <InputGuesser source={"city"}/>
    <TextInput source={"profile"} parse={v => JSON.parse(v)} format={v => JSON.stringify(v)}/>
    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </EditGuesser>
);
