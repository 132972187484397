import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {ReferenceInput, AutocompleteInput, ImageInput} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import {parse} from "query-string";

export const FormChoiceList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"key"}/>
    <FieldGuesser source={"value"}/>
    <FieldGuesser source={"formItem"}/>
  </ListGuesser>
);

export const FormChoiceCreate = props => {
  const {form_item: formItemUri} = parse(props.location.search);
  const redirect = formItemUri ? `/form_items/${formItemUri}/edit/form_choices` : false;
  return (
    <CreateGuesser {...props} redirect={redirect} initialValues={{ formItem: formItemUri}}>
      <InputGuesser source={"key"}/>
      <InputGuesser source={"value"}/>
      <ImageInput source="image" label="Image" accept="image/*"
                  placeholder={<p>Drop your file here</p>}>
        <CustomImageField source="contentUrl"/>
      </ImageInput>
      <ReferenceInput
        allowEmpty={true}
        source="formItem"
        reference="form_items"
        label="FormItem"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </CreateGuesser>
  );
}

const redirect = (basePath, id, data) => {
  const formUri = encodeURIComponent(data.form);
  return `/forms/${formUri}/choices`;
};

export const FormChoiceEdit = props => (
  <EditGuesser {...props} redirect={redirect}>
    <InputGuesser source={"key"}/>
    <InputGuesser source={"value"}/>
    <ImageInput source="image" label="Image" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>
    <ReferenceInput
      allowEmpty={true}
      source="formItem"
      reference="form_items"
      label="FormItem"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </EditGuesser>
);
