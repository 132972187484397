import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {
    AutocompleteInput,
    ReferenceInput,
    required,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ImageInput,
    FileInput,
    ArrayInput,
    SimpleFormIterator,
    SelectInput,
    NumberInput,
    TextInput,
} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import CustomVideoField from "../components/fields/CustomVideoField";
import CustomDocumentField from "../components/fields/CustomDocumentField";
import OpenAiModelSelectInput from "../components/OpenAiModelSelectInput";
import DIDVoiceSelectInput from "../components/DIDVoiceSelectInput";


export const ShowyAiConfigList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source={"name"}/>
        <FieldGuesser source={"model"}/>
        <FieldGuesser source={"temperature"}/>
        <FieldGuesser source={"language"}/>
    </ListGuesser>
);
// const models = [
//     {id: 'gpt-4o-mini', name: 'gpt 4o mini'},
//     {id: 'llama3-8b', name: 'Llama3 8b'},
//     {id: 'llama3-70b', name: 'Llama3 70b'},
//     {id: 'llama3.1-405b', name: 'Llama3.1 405b'},
// ];
const languages = [
    {id: 'en', name: 'en'},
    {id: 'ar', name: 'ar'},
    {id: 'fr', name: 'fr'},
];
const voiceProviders = [
    {id: 'microsoft', name: 'Microsoft'},
    {id: 'amazon', name: 'Amazon'},
    {id: 'google', name: 'google'},
    {id: 'afflorithmics', name: 'afflorithmics'},
    {id: 'elevenlabs', name: 'elevenlabs'},
    {id: 'playHT', name: 'playHT'},
];

const toolChoices = [
    {id: 'auto', name: 'Auto'},
    {id: 'required', name: 'Required'},
];


const speechModels = [
    {id: "whisper-1", name: "whisper-1"}
];

export const ShowyAiConfigCreate = props => {
    return (<CreateGuesser {...props}>
        <InputGuesser source={"name"} validate={[required()]}/>
        <OpenAiModelSelectInput source="model" label="Model" defaultValue="gpt-4o"/>
        <NumberInput source={"temperature"} validate={[required()]} defaultValue={0} min={0} max={1} step={0.1}/>
        <SelectInput source="language" choices={languages} validate={[required()]} defaultValue="en"/>
        <InputGuesser source={"mainPrompt"} validate={[required()]} multiline fullWidth/>
        <InputGuesser source={"mainPromptTools"} multiline fullWidth/>
        <SelectInput source="toolChoice" choices={toolChoices} validate={[required()]} defaultValue="required"/>


        <InputGuesser source={"speechToTextPrompt"} validate={[required()]} multiline fullWidth/>
        <SelectInput source="sttModel" choices={speechModels} defaultValue='whisper-1'/>
        <InputGuesser source={"allowUserToSpeakWhenAvatarIsSpeaking"} validate={[required()]} defaultValue={false}/>
        <InputGuesser source={"requireSpeechTriggerOnConversationStartOnly"} validate={[required()]}
                      defaultValue={false}/>

        <InputGuesser source={"speechPositiveThreshold"} defaultValue={0.9}/>
        <InputGuesser source={"speechMinFrames"} defaultValue={3}/>
        <InputGuesser source={"speechPrePadFrames"} defaultValue={1}/>
        <InputGuesser source={"speechRedemptionFrames"} defaultValue={8}/>
        <InputGuesser source={"speechframeSamples"} defaultValue={1536}/>
        <InputGuesser source={"tensorFlowSpeechProbabilityThreshold"} min={0.9} max={0.99} step={0.01} defaultValue={0.9}/>

        <FileInput source="speechKeywordTriggerModel" label="Speech Keyword Trigger Model"
                   placeholder={<p>Drop your tensorflowjs model.json file here</p>}>
            <CustomDocumentField source="name"/>
        </FileInput>
        <FileInput source="speechKeywordTriggerMetadata" label="Speech Keyword Trigger Metadata"
                   placeholder={<p>Drop your tensorflowjs metadata.json file here</p>}>
            <CustomDocumentField source="name"/>
        </FileInput>
        <ArrayInput source="speechKeywordTriggerWeights">
            <SimpleFormIterator inline>
                <FileInput source="" label="Speech Keyword Trigger Weight"
                           placeholder={<p>Drop your tensorflowjs wieghts.bin file here</p>}>
                    <CustomDocumentField source="name"/>
                </FileInput>
            </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="speechKeywordTriggerWords">
            <SimpleFormIterator inline>
                <TextInput label="Word"/>
            </SimpleFormIterator>
        </ArrayInput>

        <InputGuesser source="welcomeMessageTitle"
                      toolbar={[['bold', 'italic', 'underline', 'clean'], [{'direction': 'ltr'}],]}/>
        <InputGuesser source="welcomeMessageParagraph"
                      toolbar={[['bold', 'italic', 'underline', 'clean'], [{'direction': 'ltr'}],]}/>

        <ImageInput source="avatar" label="Avatar Image" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="mainBackground" label="Main Background Image" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <SelectInput source="avatarVoiceProvider" choices={voiceProviders} validate={[required()]} defaultValue="microsoft"/>
        <DIDVoiceSelectInput source="avatarVoiceId" label="Avatar Voice Id" defaultValue="fr-FR-DeniseNeural"/>

        <InputGuesser source={"sessionTimeout"}/>

        <InputGuesser source="waitingScreenMessage" />
        <FileInput source="waitingScreenVideo" label="Waiting Screen Video" accept="video/*"
                   placeholder={<p>Drop your file here</p>}>
            <CustomVideoField source="contentUrl"/>
        </FileInput>

        <ReferenceArrayInput
            source="devices"
            reference="devices"
            label="Devices"
            filterToQuery={searchText => ({name: searchText})}
            filter={{'application.slug': 'showy-ai'}}
        >
            <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput>

        <ReferenceInput
            allowEmpty={true}
            source="project"
            reference="projects"
            label="Project"
            filterToQuery={searchText => ({name: searchText})}
            validate={[required()]}
        >
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
    </CreateGuesser>)
};
export const ShowyAiConfigEdit = props => {
    return <EditGuesser {...props}>
        <InputGuesser source={"name"} validate={[required()]}/>
        <OpenAiModelSelectInput source="model" label="Model" defaultValue="gpt-4o"/>
        <NumberInput source={"temperature"} validate={[required()]} defaultValue={0} min={0} max={1} step={0.1}/>
        <SelectInput source="language" choices={languages} validate={[required()]} defaultValue="en"/>
        <InputGuesser source={"mainPrompt"} validate={[required()]} multiline fullWidth/>
        <InputGuesser source={"mainPromptTools"} multiline fullWidth/>
        <SelectInput source="toolChoice" choices={toolChoices} validate={[required()]} defaultValue="required"/>
        <InputGuesser source={"speechToTextPrompt"} validate={[required()]} multiline fullWidth/>
        <SelectInput source="sttModel" choices={speechModels} defaultValue='whisper-1'/>
        <InputGuesser source={"speechPositiveThreshold"} defaultValue={0.9}/>
        <InputGuesser source={"speechMinFrames"} defaultValue={3}/>
        <InputGuesser source={"speechPrePadFrames"} defaultValue={1}/>
        <InputGuesser source={"speechRedemptionFrames"} defaultValue={8}/>
        <InputGuesser source={"speechframeSamples"} defaultValue={1536}/>
        <InputGuesser source={"tensorFlowSpeechProbabilityThreshold"} min={0.9} max={0.99} step={0.01} defaultValue={0.9}/>
        <FileInput source="speechKeywordTriggerModel" label="Speech Keyword Trigger Model"
                   placeholder={<p>Drop your tensorflowjs model.json file here</p>}>
            <CustomDocumentField source="name"/>
        </FileInput>
        <FileInput source="speechKeywordTriggerMetadata" label="Speech Keyword Trigger Metadata"
                   placeholder={<p>Drop your tensorflowjs metadata.json file here</p>}>
            <CustomDocumentField source="name"/>
        </FileInput>
        <ArrayInput source="speechKeywordTriggerWeights">
            <SimpleFormIterator inline>
                <FileInput source="" label="Speech Keyword Trigger Weight"
                           placeholder={<p>Drop your tensorflowjs wieghts.bin file here</p>}>
                    <CustomDocumentField source="name"/>
                </FileInput>
            </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="speechKeywordTriggerWords">
            <SimpleFormIterator inline>
                <TextInput label="Word"/>
            </SimpleFormIterator>
        </ArrayInput>
        <InputGuesser source={"allowUserToSpeakWhenAvatarIsSpeaking"} validate={[required()]} defaultValue={false}/>
        <InputGuesser source={"requireSpeechTriggerOnConversationStartOnly"} validate={[required()]}
                      defaultValue={false}/>

        <InputGuesser source="welcomeMessageTitle"
                      toolbar={[['bold', 'italic', 'underline', 'clean'], [{'direction': 'ltr'}],]}/>
        <InputGuesser source="welcomeMessageParagraph"
                      toolbar={[['bold', 'italic', 'underline', 'clean'], [{'direction': 'ltr'}],]}/>

        <ImageInput source="avatar" label="Avatar Image" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <CustomVideoField source="avatarIDLEVideo"/>

        <ImageInput source="mainBackground" label="Main Background Image" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
            <CustomImageField source="contentUrl"/>
        </ImageInput>

        <SelectInput source="avatarVoiceProvider" choices={voiceProviders} validate={[required()]} defaultValue="microsoft"/>
        <DIDVoiceSelectInput source="avatarVoiceId" label="Avatar Voice Id" defaultValue="fr-FR-DeniseNeural"/>

        <InputGuesser source={"sessionTimeout"}/>

        <InputGuesser source="waitingScreenMessage" />
        <FileInput source="waitingScreenVideo" label="Waiting Screen Video" accept="video/*"
                   placeholder={<p>Drop your file here</p>}>
            <CustomVideoField source="contentUrl"/>
        </FileInput>

        <ReferenceArrayInput
            source="devices"
            reference="devices"
            label="Devices"
            filterToQuery={searchText => ({name: searchText})}
            filter={{'application.slug': 'showy-ai'}}
        >
            <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput>

        <ReferenceInput
            allowEmpty={true}
            source="project"
            reference="projects"
            label="Project"
            filterToQuery={searchText => ({name: searchText})}
            validate={[required()]}
        >
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
    </EditGuesser>
};
