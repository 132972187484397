import {CreateGuesser, EditGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {
  ReferenceInput,
  AutocompleteInput,
  ImageInput,
  FileInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  TabbedShowLayout,
  Tab,
} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import CustomVideoField from "../components/fields/CustomVideoField";
import {parse} from "query-string";
import AddQuestionButton from "./addQuestionButton";

export const QuizQuestionCategoryCreate = props => {
  const {quiz: quizUri} = parse(props.location.search);
  const encodedQuizUri = encodeURIComponent(quizUri);
  const redirect = quizUri ? `/quizzes/${encodedQuizUri}/categories` : false;
  return (
    <CreateGuesser {...props} redirect={redirect} initialValues={{quiz: quizUri}}>
      <InputGuesser source="name"/>

      <ImageInput source="image" label="Image" accept="image/*"
                  placeholder={<p>Drop your file here</p>}>
        <CustomImageField source="contentUrl"/>
      </ImageInput>

      <FileInput source="video" label="Video" accept="video/*"
                 placeholder={<p>Drop your file here</p>}>
        <CustomVideoField source="contentUrl"/>
      </FileInput>

      <ReferenceInput
        allowEmpty={true}
        source="quiz"
        reference="quizzes"
        label="Quiz"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="project"
        reference="projects"
        label="Project"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </CreateGuesser>
  );
}

const redirect = (basePath, id, data) => {
  const quizUri = encodeURIComponent(data.quiz);
  return `/quizzes/${quizUri}/categories`;
};

export const QuizQuestionCategoryEdit = props => (
  <EditGuesser {...props} redirect={redirect}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source="name"/>

        <ImageInput source="image" label="Image" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <FileInput source="video" label="Video" accept="video/*"
                   placeholder={<p>Drop your file here</p>}>
          <CustomVideoField source="contentUrl"/>
        </FileInput>

        <ReferenceInput
          allowEmpty={true}
          source="quiz"
          reference="quizzes"
          label="Quiz"
          filterToQuery={searchText => ({name: searchText})}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>

        <ReferenceInput
          allowEmpty={true}
          source="project"
          reference="projects"
          label="Project"
          filterToQuery={searchText => ({name: searchText})}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      </Tab>
        <Tab label="Questions" path="questions">
          <ReferenceManyField
            addLabel={false}
            reference="quiz_questions"
            target="category">
            <Datagrid>
              <TextField source="question"/>
              <ShowButton/>
              <EditButton/>
            </Datagrid>
          </ReferenceManyField>
          <AddQuestionButton context={'category'}/>
        </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);

