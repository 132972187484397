import React from "react";
import {Login, defaultTheme} from 'react-admin';
import {HydraAdmin, ResourceGuesser} from "@api-platform/admin";
import authProvider from "./authProvider";
import {MailConfigList, MailConfigCreate, MailConfigEdit} from './components/MailConfig';
import {DeviceList, DeviceCreate, DeviceEdit} from './device/Device';
import {ProjectCreate, ProjectEdit, ProjectList} from './components/Project';
import {ApplicationCreate, ApplicationEdit, ApplicationList} from "./components/Application";
import {DeviceTypeCreate, DeviceTypeEdit} from "./device/DeviceType";
import {DeviceCircleCreate, DeviceCircleEdit, DeviceCircleList} from "./device/DeviceCircle";
import {FormCreate, FormEdit, FormList} from "./form/Form";
import {FormStepCreate, FormStepEdit, FormStepList} from "./form/FormStep";
import {FormItemCreate, FormItemEdit, FormItemList} from "./form/FormItem";
import {FormChoiceCreate, FormChoiceEdit, FormChoiceList} from "./form/FormChoice";
import {PersonCreate, PersonEdit, PersonList, PersonShow} from "./components/Person";
import {PostCreate, PostEdit, PostList, PostShow} from "./components/Post";
import {apiDocumentationParser, entrypoint, myDataProvider} from "./data/dataProvider";
import {AccountCreate, AccountEdit, AccountList} from "./components/Account";
import {DocumentCreate, DocumentEdit, DocumentList, DocumentShow} from "./media/Document";
import {ImageCreate, ImageEdit, ImageList, ImageShow} from "./media/Image";
import {VideoCreate, VideoEdit, VideoList, VideoShow} from "./media/Video";
import {CheckInConfigCreate, CheckInConfigEdit, CheckInConfigList} from "./configuration/CheckInConfig";
import {BulletTimeConfigCreate, BulletTimeConfigEdit, BulletTimeConfigList} from "./configuration/BulletTimeConfig";
import {BoothConfigCreate, BoothConfigEdit, BoothConfigList} from "./configuration/BoothConfig";
import {PhotoBoothConfigCreate, PhotoBoothConfigEdit, PhotoBoothConfigList} from "./configuration/PhotoBoothConfig";
import {ContentWallConfigCreate, ContentWallConfigEdit, ContentWallConfigList} from "./configuration/ContentWallConfig";
import {CheckOutConfigCreate, CheckOutConfigEdit, CheckOutConfigList} from "./configuration/CheckOutConfig";
import {ComparatorConfigCreate, ComparatorConfigEdit, ComparatorConfigList} from "./comparator/ComparatorConfig";
import {ComparableItemCreate, ComparableItemEdit, ComparableItemList} from "./comparator/ComparableItem";
import {AttributeCreate, AttributeEdit} from "./comparator/attribute";
import {AttributeValueCreate, AttributeValueEdit} from "./comparator/attributeValue";
import {BrandCreate, BrandEdit, BrandList} from "./product/Brand";
import {ProductCreate, ProductEdit, ProductList} from "./product/Product";
import {ProductVersionCreate, ProductVersionEdit} from "./product/ProductVersion";
import {ZohoTagConfigCreate, ZohoTagConfigEdit, ZohoTagConfigList} from "./components/ZohoTagConfig";
import {ConfiguratorItemCreate, ConfiguratorItemEdit, ConfiguratorItemList} from "./configurator/ConfiguratorItem";
import {
    ConfiguratorConfigCreate,
    ConfiguratorConfigEdit,
    ConfiguratorConfigList
} from "./configurator/ConfiguratorConfig";
import {ProductColorCreate, ProductColorEdit} from "./product/ProductColor";
import {QuizCreate, QuizEdit, QuizList} from "./quiz/Quiz";
import {
    QuizQuestionCategoryCreate,
    QuizQuestionCategoryEdit,
} from "./quiz/QuizQuestionCategory";
import {QuizQuestionCreate, QuizQuestionEdit} from "./quiz/QuizQuestion";
import {Layout} from "./Layout";
import {QuizConfigCreate, QuizConfigEdit, QuizConfigList} from "./quiz/ِQuizConfig";
import {
    ConfigurationItemValueCreate,
    ConfigurationItemValueEdit,
    ConfigurationItemValueList
} from "./configuration/ConfigurationItemValue";
import merge from 'lodash/merge';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';
import {UserCreate, UserEdit, UserList, UserShow} from "./user/User";
import isGranted from "./security";
import {useThemeState} from "./theme";
import Dashboard from './Dashboard/Dashboard'
import {PlayListCreate, PlayListEdit, PlayListList} from "./playList/PlayList";
import {PlayListItemCreate, PlayListItemEdit} from "./playList/PlayListItem";
import {MediaPlayerConfigCreate, MediaPlayerConfigEdit, MediaPlayerConfigList} from "./playList/MediaPlayerConfig";
import {ProductRangeCreate, ProductRangeEdit, ProductRangeList} from "./product/ProductRange";
import {DeskConfigurationCreate, DeskConfigurationEdit, DeskConfigurationList} from "./configuration/DeskConfiguration";
import {DeskHeroProductCreate, DeskHeroProductEdit, HeroProductList} from "./configuration/DeskHeroProduct";
import {CategoryCreate, CategoryEdit, CategoryList} from "./product/Category";
import {ProductVariantCreate, ProductVariantEdit} from "./product/ProductVariant";
import { ShowyAiConfigCreate, ShowyAiConfigEdit, ShowyAiConfigList } from "./showy/ShowyAiConfig";
import {AiToolCreate, AiToolEdit, AiToolList} from "./aiTool/AiTool";
import {AiParameterCreate, AiParameterEdit} from "./aiTool/AiParameter";

const MyLoginPage = () => <Login backgroundImage="/share-technologies.jpg"/>;


export const darkTheme = {
    palette: {
        type: 'dark'
    },
    overrides: {
        MuiAppBar: {
            colorSecondary: {
                backgroundColor: '#424242', //'#1e4c9a',
                color: '#fff'
            },
        },
        MuiButton: {
            textPrimary: {
                color: '#fff',
            }
        },
        MuiTypography: {
            colorPrimary: {
                color: '#fff'
            }
        },
        MuiDrawer: {
            paper: {
                //paddingTop: '20px'
            }
        },
        MuiFilledInput: {
            underline: {
                '&:after': {
                    borderBottomColor: '#bf9f00'
                }
            }
        },
        MuiFormLabel: {
            root: {
                '&$focused': {
                    color: '#bf9f00'
                }
            }
        },
    }
}


const lightTheme = merge({}, defaultTheme, {
    palette: {
        primary: indigo,
        secondary: pink,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },
    overrides: {
        MuiButton: { // override the styles of all instances of this component
            root: { // Name of the rule
                //color: 'white', // Some CSS
            },
        },
    },
});

export default () => {
    const {theme} = useThemeState();

    return (
        <HydraAdmin
            theme={theme === 'light' ? lightTheme : darkTheme}
            layout={Layout}
            loginPage={MyLoginPage}
            apiDocumentationParser={apiDocumentationParser}
            dataProvider={myDataProvider}
            dashboard={Dashboard}
            authProvider={authProvider}
            entrypoint={entrypoint}
        >
            {permissions => [
                <ResourceGuesser name={"accounts"} list={AccountList} create={AccountCreate} edit={AccountEdit}/>,

                <ResourceGuesser name={"projects"} list={ProjectList} create={ProjectCreate} edit={ProjectEdit}/>,
                <ResourceGuesser name={"users"} list={UserList} create={UserCreate} edit={UserEdit} show={UserShow}/>,
                <ResourceGuesser name={"api_tokens"}/>,
                <ResourceGuesser name={"brands"} list={BrandList} create={BrandCreate} edit={BrandEdit}/>,
                <ResourceGuesser name={"product_ranges"} list={ProductRangeList} create={ProductRangeCreate}
                                 edit={ProductRangeEdit}/>,
                <ResourceGuesser name={"products"} list={ProductList} create={ProductCreate} edit={ProductEdit}/>,
                <ResourceGuesser name={"product_versions"} create={ProductVersionCreate} edit={ProductVersionEdit}/>,
                <ResourceGuesser name={"product_colors"} create={ProductColorCreate} edit={ProductColorEdit}/>,
                <ResourceGuesser name={"product_variants"} create={ProductVariantCreate} edit={ProductVariantEdit}/>,
                <ResourceGuesser name={"mail_configs"} list={MailConfigList} create={MailConfigCreate}
                                 edit={MailConfigEdit}/>,
                <ResourceGuesser name={"zoho_tag_configs"} list={ZohoTagConfigList} create={ZohoTagConfigCreate}
                                 edit={ZohoTagConfigEdit}/>,
                <ResourceGuesser name={"applications"} list={ApplicationList} create={ApplicationCreate}
                                 edit={ApplicationEdit}/>,
                <ResourceGuesser name={"devices"} list={DeviceList} create={DeviceCreate} edit={DeviceEdit}/>,
                <ResourceGuesser name={"device_types"} create={DeviceTypeCreate} edit={DeviceTypeEdit}/>,
                <ResourceGuesser name={"device_circles"} list={DeviceCircleList} create={DeviceCircleCreate}
                                 edit={DeviceCircleEdit}/>,
                <ResourceGuesser name={"forms"} list={FormList} create={FormCreate} edit={FormEdit}/>,
                <ResourceGuesser name={"form_steps"} list={FormStepList} create={FormStepCreate} edit={FormStepEdit}/>,
                <ResourceGuesser name={"form_items"} list={FormItemList} create={FormItemCreate} edit={FormItemEdit}/>,
                <ResourceGuesser name={"form_choices"} list={FormChoiceList} create={FormChoiceCreate}
                                 edit={FormChoiceEdit}/>,
                <ResourceGuesser name={"people"} list={PersonList}
                                 create={isGranted('ROLE_ADMIN', permissions) ? PersonCreate : false}
                                 edit={isGranted('ROLE_ADMIN', permissions) ? PersonEdit : false}
                                 show={PersonShow}/>,
                <ResourceGuesser name={"posts"} list={PostList}
                                 create={isGranted('ROLE_ADMIN', permissions) ? PostCreate : false}
                                 edit={isGranted('ROLE_ADMIN', permissions) ? PostEdit : false}
                                 show={PostShow}/>,
                <ResourceGuesser name={"documents"} list={DocumentList} create={DocumentCreate} show={DocumentShow}
                                 edit={DocumentEdit}/>,
                <ResourceGuesser name={"images"} list={ImageList} create={ImageCreate} edit={ImageEdit}
                                 show={ImageShow}/>,
                <ResourceGuesser name={"videos"} list={VideoList} create={VideoCreate} edit={VideoEdit}
                                 show={VideoShow}/>,
                <ResourceGuesser name={"check_in_configs"} list={CheckInConfigList} create={CheckInConfigCreate}
                                 edit={CheckInConfigEdit}/>,
                <ResourceGuesser name={"bullet_time_configs"} list={BulletTimeConfigList}
                                 create={BulletTimeConfigCreate}
                                 edit={BulletTimeConfigEdit}/>,
                <ResourceGuesser name={"booth_configs"} list={BoothConfigList} create={BoothConfigCreate}
                                 edit={BoothConfigEdit}/>,
                <ResourceGuesser name={"photo_booth_configs"} list={PhotoBoothConfigList}
                                 create={PhotoBoothConfigCreate}
                                 edit={PhotoBoothConfigEdit}/>,
                <ResourceGuesser name={"content_wall_configs"} list={ContentWallConfigList}
                                 create={ContentWallConfigCreate}
                                 edit={ContentWallConfigEdit}/>,
                <ResourceGuesser name={"check_out_configs"} list={CheckOutConfigList} create={CheckOutConfigCreate}
                                 edit={CheckOutConfigEdit}/>,
                <ResourceGuesser name={"comparator_configs"} list={ComparatorConfigList} create={ComparatorConfigCreate}
                                 edit={ComparatorConfigEdit}/>,
                <ResourceGuesser name={"comparable_items"} list={ComparableItemList} create={ComparableItemCreate}
                                 edit={ComparableItemEdit}/>,
                <ResourceGuesser name={"configurator_configs"} list={ConfiguratorConfigList}
                                 create={ConfiguratorConfigCreate}
                                 edit={ConfiguratorConfigEdit}/>,
                <ResourceGuesser name={"configurator_items"} list={ConfiguratorItemList} create={ConfiguratorItemCreate}
                                 edit={ConfiguratorItemEdit}/>,
                <ResourceGuesser name={"quizzes"} list={QuizList} create={QuizCreate} edit={QuizEdit}/>,
                <ResourceGuesser name={"quiz_question_categories"} create={QuizQuestionCategoryCreate}
                                 edit={QuizQuestionCategoryEdit}/>,
                <ResourceGuesser name={"quiz_questions"} create={QuizQuestionCreate} edit={QuizQuestionEdit}/>,
                <ResourceGuesser name={"quiz_configs"} list={QuizConfigList} create={QuizConfigCreate}
                                 edit={QuizConfigEdit}/>,
                <ResourceGuesser name={"configuration_item_values"} list={ConfigurationItemValueList}
                                 create={ConfigurationItemValueCreate} edit={ConfigurationItemValueEdit}/>,
                <ResourceGuesser name={"vehicle_models"}/>,
                <ResourceGuesser name={"play_lists"} list={PlayListList} create={PlayListCreate} edit={PlayListEdit}/>,
                <ResourceGuesser name={"play_list_items"} create={PlayListItemCreate} edit={PlayListItemEdit}/>,
                <ResourceGuesser name={"media_player_configs"} create={MediaPlayerConfigCreate}
                                 edit={MediaPlayerConfigEdit} list={MediaPlayerConfigList}/>,
                <ResourceGuesser name={"desk_configurations"} create={DeskConfigurationCreate}
                                 edit={DeskConfigurationEdit} list={DeskConfigurationList}/>,
                <ResourceGuesser name={"desk_hero_products"} create={DeskHeroProductCreate} edit={DeskHeroProductEdit}
                                 list={HeroProductList}/>,
                <ResourceGuesser name={"cities"}/>,
                <ResourceGuesser name={"visit_session_stats"}/>,
                <ResourceGuesser name={"action_session_stats"}/>,
                <ResourceGuesser name={"categories"} list={CategoryList} create={CategoryCreate} edit={CategoryEdit}/>,
                <ResourceGuesser name={"attributes"} create={AttributeCreate} edit={AttributeEdit}/>,
                <ResourceGuesser name={"attribute_values"} create={AttributeValueCreate} edit={AttributeValueEdit}/>,
                <ResourceGuesser name={"showy_ai_configs"} list={ShowyAiConfigList} create={ShowyAiConfigCreate} edit={ShowyAiConfigEdit}/>,
                <ResourceGuesser name={"ai_tools"} list={AiToolList} create={AiToolCreate} edit={AiToolEdit}/>,
                <ResourceGuesser name={"ai_tool_parameters"} create={AiParameterCreate} edit={AiParameterEdit}/>,
                <ResourceGuesser name={"open_ai_models"} create={false} edit={false} />,
                <ResourceGuesser name={"d_id_voices"} create={false} edit={false} />,
            ]}
        </HydraAdmin>
    );
}
