import {CreateGuesser, EditGuesser} from "@api-platform/admin";
import React from "react";
import {
  ReferenceInput,
  AutocompleteInput,
  ArrayInput,
  BooleanInput,
  SimpleFormIterator,
} from "react-admin";
import {parse} from "query-string";
import RichTextInput from 'ra-input-rich-text';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles({
  choices: {
    paddingLeft: '40px',
  },
});

export const QuizQuestionCreate = props => {
  const classes = useStyles();

  const {context, quiz: quizUri, category: categoryUri} = parse(props.location.search);
  const encodedQuizUri = encodeURIComponent(quizUri);
  const encodedCategoryUri = encodeURIComponent(categoryUri);
  const redirect = context === 'quiz' ? `/quizzes/${encodedQuizUri}/questions` : context === 'category' ? `/quiz_question_categories/${encodedCategoryUri}/questions` : false;
  return (
    <CreateGuesser {...props} redirect={redirect} initialValues={{category: categoryUri, quiz: quizUri}}>
      <RichTextInput source="question"
                     toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>

      <ReferenceInput
        allowEmpty={true}
        source="quiz"
        reference="quizzes"
        label="Quiz"
        filterToQuery={searchText => ({name: searchText})}>
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="category"
        reference="quiz_question_categories"
        label="Category"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="project"
        reference="projects"
        label="Project"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ArrayInput source="choices">
        <SimpleFormIterator className={classes.choices}>
          <RichTextInput label={"Choice"} source="choice"
                         toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}],]}/>
          <BooleanInput label="Correct" source="correct"/>
        </SimpleFormIterator>
      </ArrayInput>

    </CreateGuesser>
  );
}

const redirect = (basePath, id, data) => {
  const quizUri = encodeURIComponent(data.quiz);
  const categoryUri = data.category ? encodeURIComponent(data.category['@id']) : null;
  return data.category ? `/quiz_question_categories/${categoryUri}/questions` : `/quizzes/${quizUri}/questions`;
};

export const QuizQuestionEdit = props => {
  const classes = useStyles();
  return (
    <EditGuesser {...props} redirect={redirect}>
      <RichTextInput source="question"
                     toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}], [{'color': []}, {'background': []}],]}/>

      <ReferenceInput
        allowEmpty={true}
        source="quiz"
        reference="quizzes"
        label="Quiz"
        filterToQuery={searchText => ({name: searchText})}
        format={v => v['@id'] || v}>
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="category"
        reference="quiz_question_categories"
        label="Category"
        filterToQuery={searchText => ({name: searchText})}
        format={v => v['@id'] || v}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="project"
        reference="projects"
        label="Project"
        filterToQuery={searchText => ({name: searchText})}
        format={v => v['@id'] || v}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ArrayInput source="choices">
        <SimpleFormIterator className={classes.choices}>
          <RichTextInput label={"Choice"} source="choice"
                         toolbar={[['bold', 'italic', 'underline', 'link', 'clean'], [{'direction': 'rtl'}], [{'color': []}, {'background': []}],]}/>
          <BooleanInput label="Correct" source="correct"/>
        </SimpleFormIterator>
      </ArrayInput>

    </EditGuesser>
  )
};
