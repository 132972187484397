import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import CustomImageField from "../components/fields/CustomImageField";
import {
  ReferenceInput,
  AutocompleteInput,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  ImageInput,
  required,
  FileInput
} from "react-admin";
import CustomDocumentField from "../components/fields/CustomDocumentField";
import {parse} from "query-string";
import AddProductVersionButton from "./addProductVersionButton";
import AddProductColorButton from "./addProductColorButton";
import AddProductVariantButton from "./addProductVariantButton";

export const ProductList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"}/>
  </ListGuesser>
);

export const ProductCreate = props => {
  const {brand: brandUri} = parse(props.location.search);
  const {productRange: productRangeUri} = parse(props.location.search);
  const {project: projectUri} = parse(props.location.search);
  let redirect = '/products';
  if (productRangeUri) {
    const encodedProductRangeUri = encodeURIComponent(productRangeUri);
    redirect = `/product_ranges/${encodedProductRangeUri}/products`
  }else if (brandUri) {
    const encodedBrandUri = encodeURIComponent(brandUri);
    redirect = `/brands/${encodedBrandUri}/products`
  }

  return (
    <CreateGuesser {...props} redirect={redirect} initialValues={{ brand: brandUri, productRange: productRangeUri, project: projectUri}}>
      <InputGuesser source="name"/>

      <ImageInput source="photo" label="Photo" accept="image/*"
                  placeholder={<p>Drop your file here</p>}>
        <CustomImageField source="contentUrl"/>
      </ImageInput>

      <ImageInput source="heroImage" label="Hero image" accept="image/*"
                  placeholder={<p>Drop your file here</p>}>
        <CustomImageField source="contentUrl"/>
      </ImageInput>

      <FileInput source="technicalDocument" label="Technical Document" placeholder={<p>Drop your file here</p>}>
        <CustomDocumentField source="name"/>
      </FileInput>

      <ReferenceInput
        allowEmpty={true}
        source="project"
        reference="projects"
        label="Project"
        filterToQuery={searchText => ({name: searchText})}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="brand"
        reference="brands"
        label="Brand"
        filterToQuery={searchText => ({name: searchText})}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="category"
        reference="categories"
        label="Category"
        filterToQuery={searchText => ({name: searchText})}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="productRange"
        reference="product_ranges"
        label="Product Range"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </CreateGuesser>
  );
}

const redirect = (basePath, id, data) => {
  const brandUri = encodeURIComponent(data.brand);
  return brandUri ?  `/brands/${brandUri}/products` : '/products';
};

export const ProductEdit = props => (
  <EditGuesser {...props} redirect={redirect}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source="name"/>

        <ImageInput source="photo" label="Photo" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="heroImage" label="Hero image" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <FileInput source="technicalDocument" label="Technical Document" placeholder={<p>Drop your file here</p>}>
          <CustomDocumentField source="name"/>
        </FileInput>

        <ReferenceInput
          allowEmpty={true}
          source="project"
          reference="projects"
          label="Project"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>

        <ReferenceInput
          allowEmpty={true}
          source="brand"
          reference="brands"
          label="Brand"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>

        <ReferenceInput
          allowEmpty={true}
          source="category"
          reference="categories"
          label="Category"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>

        <ReferenceInput
          allowEmpty={true}
          source="productRange"
          reference="product_ranges"
          label="Product Range"
          filterToQuery={searchText => ({name: searchText})}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      </Tab>
      <Tab label="Versions" path="versions">
        <ReferenceManyField
          addLabel={false}
          reference="product_versions"
          target="product">
          <Datagrid>
            <TextField source="name"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddProductVersionButton/>
      </Tab>
      <Tab label="Colors" path="colors">
        <ReferenceManyField
          addLabel={false}
          reference="product_colors"
          target="product">
          <Datagrid>
            <TextField source="name"/>
            <TextField source="colorName"/>
            <TextField source="colorValue"/>
            <TextField source="position"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddProductColorButton context={'product'}/>
      </Tab>
      <Tab label="Variants" path="variants">
        <ReferenceManyField
          addLabel={false}
          reference="product_variants"
          target="product">
          <Datagrid>
            <TextField source="name"/>
            <TextField source="price"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddProductVariantButton context={'product'}/>
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
