import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import CustomImageField from "../components/fields/CustomImageField";
import {
  ReferenceInput,
  AutocompleteInput,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  ImageInput,
  required,
} from "react-admin";
import {parse} from "query-string";
import AddProductButton from "./addProductButton";

export const ProductRangeList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"position"}/>
  </ListGuesser>
);

export const ProductRangeCreate = props => {
  const {brand: brandUri} = parse(props.location.search);
  const encodedBrandUri = encodeURIComponent(brandUri);
  const redirect = brandUri ? `/brands/${encodedBrandUri}/product_ranges` : '/product_ranges';
  return (
    <CreateGuesser {...props} redirect={redirect} initialValues={{ brand: brandUri}}>
      <InputGuesser source="name"/>
      <InputGuesser source="position"/>

      <ImageInput source="photo" label="Photo" accept="image/*"
                  placeholder={<p>Drop your file here</p>}>
        <CustomImageField source="contentUrl"/>
      </ImageInput>

      <ReferenceInput
        allowEmpty={true}
        source="project"
        reference="projects"
        label="Project"
        filterToQuery={searchText => ({name: searchText})}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="brand"
        reference="brands"
        label="Brand"
        filterToQuery={searchText => ({name: searchText})}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="category"
        reference="categories"
        label="Category"
        filterToQuery={searchText => ({name: searchText})}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </CreateGuesser>
  );
}

/*const redirect = (basePath, id, data) => {
  const brandUri = encodeURIComponent(data.brand);
  return brandUri ?  `/brands/${brandUri}/product_ranges` : '/product_ranges';
};*/

export const ProductRangeEdit = props => (
  <EditGuesser {...props} redirect="/product_ranges">
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source="name"/>
        <InputGuesser source="position"/>

        <ImageInput source="photo" label="Photo" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ReferenceInput
          allowEmpty={true}
          source="project"
          reference="projects"
          label="Project"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>

        <ReferenceInput
          allowEmpty={true}
          source="brand"
          reference="brands"
          label="Brand"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>

        <ReferenceInput
          allowEmpty={true}
          source="category"
          reference="categories"
          label="Category"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      </Tab>
      <Tab label="Products" path="products">
        <ReferenceManyField
          addLabel={false}
          reference="products"
          target="productRange">
          <Datagrid>
            <TextField source="name"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddProductButton/>
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
