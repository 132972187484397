import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  ImageInput,
  required,
  minLength,
  maxLength,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  ReferenceManyField,
  Tab,
  TabbedShowLayout,
} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import AddConfigurationItemButton from "./addConfigurationItemButton";

export const BoothConfigList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"brandingEnabled"}/>
  </ListGuesser>
);

export const BoothConfigCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} validate={[required()]}/>

    <ImageInput source="background" label="background" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"backgroundColor"} validate={[minLength(4), maxLength(4)]}/>
    <InputGuesser source={"title"}/>

    <ImageInput source="headerBackground" label="headerBackground" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <ImageInput source="footerBackground" label="footerBackground" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"textColor"} validate={[minLength(4), maxLength(4)]}/>

    <ImageInput source="scanIcon" label="scanIcon" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"scanMessage"}/>
    <InputGuesser source={"scanMessageColor"} validate={[minLength(4), maxLength(4)]}/>
    <InputGuesser source={"scanErrorMessage"}/>

    <InputGuesser source={"selectFileLabel"}/>
    <InputGuesser source={"selectFileTextColor"} validate={[minLength(4), maxLength(4)]}/>
    <InputGuesser source={"selectFileBackgroundColor"} validate={[minLength(4), maxLength(4)]}/>
    <InputGuesser source={"selectFileErrorMessage"}/>

    <InputGuesser source={"sendButtonLabel"}/>
    <InputGuesser source={"sendButtonTextColor"} validate={[minLength(4), maxLength(4)]}/>
    <InputGuesser source={"sendButtonBackgroundColor"} validate={[minLength(4), maxLength(4)]}/>

    <InputGuesser source={"cancelButtonLabel"}/>
    <InputGuesser source={"cancelButtonTextColor"} validate={[minLength(4), maxLength(4)]}/>
    <InputGuesser source={"cancelButtonBackgroundColor"} validate={[minLength(4), maxLength(4)]}/>

    <InputGuesser source={"newParticipationButtonLabel"}/>
    <InputGuesser source={"newParticipationButtonTextColor"} validate={[minLength(4), maxLength(4)]}/>
    <InputGuesser source={"newParticipationButtonBackgroundColor"} validate={[minLength(4), maxLength(4)]}/>

    <InputGuesser source={"successMessage"}/>
    <InputGuesser source={"errorMessageColor"} validate={[minLength(4), maxLength(4)]}/>
    <InputGuesser source={"brandingEnabled"}/>

    <ImageInput source="brandingBottomLeftLogo" label="BrandingBottomLeftLogo" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <ImageInput source="brandingBottomRightLogo" label="BrandingBottomRightLogo" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <ImageInput source="brandingTopLeftLogo" label="BrandingTopLeftLogo" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <ImageInput source="brandingTopRightLogo" label="BrandingTopRightLogo" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"cssStyle"}/>

    <ReferenceArrayInput
      source="devices"
      reference="devices"
      label="Devices"
      filterToQuery={searchText => ({name: searchText})}
      filter={{ 'application.slug': 'photo-booth'}}
    >
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>

    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </CreateGuesser>
);
export const BoothConfigEdit = props => (
  <EditGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source={"name"} validate={[required()]}/>

        <ImageInput source="background" label="background" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"backgroundColor"} validate={[minLength(4), maxLength(4)]}/>
        <InputGuesser source={"title"}/>

        <ImageInput source="headerBackground" label="headerBackground" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="footerBackground" label="footerBackground" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"textColor"} validate={[minLength(4), maxLength(4)]}/>

        <ImageInput source="scanIcon" label="scanIcon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"scanMessage"}/>
        <InputGuesser source={"scanMessageColor"} validate={[minLength(4), maxLength(4)]}/>
        <InputGuesser source={"scanErrorMessage"}/>

        <InputGuesser source={"selectFileLabel"}/>
        <InputGuesser source={"selectFileTextColor"} validate={[minLength(4), maxLength(4)]}/>
        <InputGuesser source={"selectFileBackgroundColor"} validate={[minLength(4), maxLength(4)]}/>
        <InputGuesser source={"selectFileErrorMessage"}/>

        <InputGuesser source={"sendButtonLabel"}/>
        <InputGuesser source={"sendButtonTextColor"} validate={[minLength(4), maxLength(4)]}/>
        <InputGuesser source={"sendButtonBackgroundColor"} validate={[minLength(4), maxLength(4)]}/>

        <InputGuesser source={"cancelButtonLabel"}/>
        <InputGuesser source={"cancelButtonTextColor"} validate={[minLength(4), maxLength(4)]}/>
        <InputGuesser source={"cancelButtonBackgroundColor"} validate={[minLength(4), maxLength(4)]}/>

        <InputGuesser source={"newParticipationButtonLabel"}/>
        <InputGuesser source={"newParticipationButtonTextColor"} validate={[minLength(4), maxLength(4)]}/>
        <InputGuesser source={"newParticipationButtonBackgroundColor"} validate={[minLength(4), maxLength(4)]}/>

        <InputGuesser source={"successMessage"}/>
        <InputGuesser source={"errorMessageColor"} validate={[minLength(4), maxLength(4)]}/>
        <InputGuesser source={"brandingEnabled"}/>

        <ImageInput source="brandingBottomLeftLogo" label="BrandingBottomLeftLogo" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="brandingBottomRightLogo" label="BrandingBottomRightLogo" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="brandingTopLeftLogo" label="BrandingTopLeftLogo" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <ImageInput source="brandingTopRightLogo" label="BrandingTopRightLogo" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"cssStyle"}/>

        <ReferenceArrayInput
          source="devices"
          reference="devices"
          label="Devices"
          filterToQuery={searchText => ({name: searchText})}
          filter={{ 'application.slug': 'photo-booth'}}
        >
          <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput>

        <ReferenceInput
          allowEmpty={true}
          source="project"
          reference="projects"
          label="Project"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      </Tab>
      <Tab label="Configurations" path="configurations">
        <ReferenceManyField
          addLabel={false}
          reference="configuration_item_values"
          target="boothConfig">
          <Datagrid>
            <TextField source="name"/>
            <TextField source="type"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddConfigurationItemButton context={'boothConfig'}/>
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
