import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {FileInput, UrlField, TextField, Show, Labeled, SimpleShowLayout} from "react-admin";
import CustomDocumentField from "../components/fields/CustomDocumentField";
import FileSizeField from "../components/fields/FileSizeField";

export const DocumentList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"}/>
    <UrlField source={"contentUrl"}/>
  </ListGuesser>
);

export const DocumentCreate = props => (
  <CreateGuesser {...props}>
    <FileInput source="file" label="File" placeholder={<p>Drop your file here</p>}>
      <CustomDocumentField source="name"/>
    </FileInput>
  </CreateGuesser>
);

export const DocumentEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source={"name"}/>
  </EditGuesser>
);

export const DocumentShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source={"name"}/>
      <TextField source={"width"}/>
      <TextField source={"height"}/>
      <Labeled label="Content size">
        <FileSizeField source={"contentSize"}/>
      </Labeled>
      <TextField source={"contentPath"}/>
      <TextField source={"encodingFormat"}/>
      <TextField source={"fileFormat"}/>
      <TextField source={"spaceKey"}/>
      <UrlField source={"contentUrl"}/>
    </SimpleShowLayout>
  </Show>
);
