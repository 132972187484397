import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {ReferenceInput, AutocompleteInput, TextInput} from "react-admin";
import {parse} from "query-string";

export const DeviceList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"deviceUID"}/>
  </ListGuesser>
);

export const DeviceCreate = props => {
  const {deviceCircle: deviceCircleUri} = parse(props.location.search);
  const encodedDeviceCircleUri = encodeURIComponent(deviceCircleUri);
  const redirect = deviceCircleUri ? `/device_circles/${encodedDeviceCircleUri}/devices` : '/devices';

  return (
    <CreateGuesser {...props} redirect={redirect} initialValues={{ deviceCircle: deviceCircleUri}}>
      <InputGuesser source="name"/>
      <InputGuesser source="deviceUID"/>
      <InputGuesser source="webSocketUrl"/>

      <ReferenceInput
        allowEmpty={true}
        source="type"
        reference="device_types"
        label="DeviceType"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="project"
        reference="projects"
        label="Project"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="brand"
        reference="brands"
        label="Brand"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="deviceCircle"
        reference="device_circles"
        label="Device Circle"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="application"
        reference="applications"
        label="Application"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="deskConfiguration"
        reference="desk_configurations"
        label="Desk Configuration"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="contentWallConfig"
        reference="content_wall_configs"
        label="ContentWallConfig"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="checkInConfig"
        reference="check_in_configs"
        label="CheckInConfig"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="checkOutConfig"
        reference="check_out_configs"
        label="CheckOutConfig"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="bulletTimeConfig"
        reference="bullet_time_configs"
        label="BulletTimeConfig"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="boothConfig"
        reference="booth_configs"
        label="BoothConfig"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="photoBoothConfig"
        reference="photo_booth_configs"
        label="PhotoBoothConfig"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="comparatorConfig"
        reference="comparator_configs"
        label="ComparatorConfig"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="configuratorConfig"
        reference="configurator_configs"
        label="ConfiguratorConfig"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="quizConfig"
        reference="quiz_configs"
        label="QuizConfig"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
          allowEmpty={true}
          source="showyAiConfig"
          reference="showy_ai_configs"
          label="Showy Ai Config"
          filterToQuery={searchText => ({name: searchText})}>
          <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="user"
        reference="users"
        label="User"
        filterToQuery={searchText => ({username: searchText})}
      >
        <AutocompleteInput optionText="username"/>
      </ReferenceInput>

      <TextInput multiline source="appConfig"/>
    </CreateGuesser>
  );
}

const redirect = (basePath, id, data) => {
  const deviceCircleUri = encodeURIComponent(data.deviceCircle);
  return data.deviceCircle ? `/device_circles/${deviceCircleUri}/devices` : `/devices`;
};

export const DeviceEdit = props => (
  <EditGuesser {...props} redirect={redirect}>
    <InputGuesser source="name"/>
    <InputGuesser source="deviceUID"/>
    <InputGuesser source="webSocketUrl"/>

    <ReferenceInput
      allowEmpty={true}
      source="type"
      reference="device_types"
      label="Device type"
      filterToQuery={searchText => ({name: searchText})}
      format={v => (v && v['@id']) || v}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="brand"
      reference="brands"
      label="Brand"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="deviceCircle"
      reference="device_circles"
      label="Device Circle"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="application"
      reference="applications"
      label="Application"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="deskConfiguration"
      reference="desk_configurations"
      label="Desk Configuration"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="contentWallConfig"
      reference="content_wall_configs"
      label="ContentWallConfig"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="checkInConfig"
      reference="check_in_configs"
      label="CheckInConfig"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="checkOutConfig"
      reference="check_out_configs"
      label="CheckOutConfig"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="bulletTimeConfig"
      reference="bullet_time_configs"
      label="BulletTimeConfig"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="boothConfig"
      reference="booth_configs"
      label="BoothConfig"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="photoBoothConfig"
      reference="photo_booth_configs"
      label="PhotoBoothConfig"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="comparatorConfig"
      reference="comparator_configs"
      label="ComparatorConfig"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="quizConfig"
      reference="quiz_configs"
      label="QuizConfig"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
        allowEmpty={true}
        source="showyAiConfig"
        reference="showy_ai_configs"
        label="Showy Ai Config"
        filterToQuery={searchText => ({name: searchText})}>
        <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="user"
      reference="users"
      label="User"
      filterToQuery={searchText => ({username: searchText})}
    >
      <AutocompleteInput optionText="username"/>
    </ReferenceInput>

    <TextInput multiline source="appConfig"/>
  </EditGuesser>
);

