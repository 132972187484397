import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {
  TabbedShowLayout,
  ReferenceInput,
  AutocompleteInput,
  Tab,
  ReferenceManyField,
  TextField,
  ShowButton,
  EditButton,
  Datagrid,
  required,
  SelectInput,
  FormDataConsumer
} from "react-admin";
import {parse} from 'query-string';
import AddFormChoiceButton from "./addFormChoiceButton";

const formItemTypes = [
  {id: 'text', name: 'Text'},
  {id: 'email', name: 'Email'},
  {id: 'tel', name: 'Tel'},
  {id: 'choice', name: 'Choice'},
  {id: 'number', name: 'number'},
  {id: 'button', name: 'Button'},
  {id: 'color', name: 'Color'},
  {id: 'checkbox', name: 'CheckBox'},
  {id: 'date', name: 'Date'},
  {id: 'datetime', name: 'Datetime'},
  {id: 'datetime-local', name: 'Datetime local'},
  {id: 'time', name: 'Time'},
  {id: 'month', name: 'Hidden'},
  {id: 'range', name: 'Range'},
  {id: 'reset', name: 'Reset'},
  {id: 'search', name: 'Search'},
  {id: 'submit', name: 'Submit'},
  {id: 'password', name: 'Password'},
  {id: 'textarea', name: 'Text Area'},
  {id: 'file', name: 'File'},
  {id: 'hidden', name: 'Hidden'},
  {id: 'url', name: 'URL'},
  {id: 'week', name: 'Week'},
  {id: 'city', name: 'City'},
];

export const FormItemList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"label"}/>
    <FieldGuesser source={"type"}/>
    <FieldGuesser source={"enabled"}/>
    <FieldGuesser source={"required"}/>
    <FieldGuesser source={"order"}/>
  </ListGuesser>
);

export const FormItemCreate = props => {
  const {context, form: formUri, form_step: formStepUri} = parse(props.location.search);
  const encodedFormUri = encodeURIComponent(formUri);
  const encodedFormStepUri = encodeURIComponent(formStepUri);

  const redirect = context === 'form' ? `/forms/${encodedFormUri}/items` : formStepUri ? `/form_steps/${encodedFormStepUri}/items` : false;

  return (
    <CreateGuesser {...props} redirect={redirect} initialValues={{form: formUri, formStep: formStepUri}}>
      <InputGuesser source={"label"} validate={[required()]}/>
      <InputGuesser source={"name"} validate={[required()]}/>
      <InputGuesser source={"syncFieldName"}/>
      <div fullWidth>
        <h6>Zoho Field names (case sensitive)</h6>
        <p>FirstName, LastName, FullName, Email, Phone, City, Street, ZipCode, Company, NoOfEmployees, LeadSource,
          Owner, Description, Rating, Website, Twitter, Salutation, LeadStatus, Industry, SkypeID, Designation, Mobile,
          Fax, AnnualRevenue, SecondaryEmail</p>
      </div>
      <SelectInput source="type" choices={formItemTypes} validate={[required()]}
      />
      <InputGuesser source={"value"}/>
      <InputGuesser source={"selected"}/>
      <InputGuesser source={"placeHolder"}/>
      <InputGuesser source={"maxLength"}/>
      <InputGuesser source={"minLength"}/>
      <InputGuesser source={"multiple"}/>
      <InputGuesser source={"expanded"}/>
      <InputGuesser source={"required"}/>
      <InputGuesser source={"order"} validate={[required()]}/>
      <InputGuesser source={"enabled"}/>
      <ReferenceInput
        allowEmpty={true}
        source="form"
        reference="forms"
        label="Form"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <ReferenceInput
        source="formStep"
        reference="form_steps"
        label="FormStep"
        filterToQuery={searchText => ({title: searchText})}
      >
        <AutocompleteInput optionText="title"/>
      </ReferenceInput>
    </CreateGuesser>
  );
}

const redirect = (basePath, id, data) => {
  const formUri = encodeURIComponent(data.form);
  const formStepUri = data.formStep ? encodeURIComponent(data.formStep) : null;
  return data.formStep ? `/form_steps/${formStepUri}/items` : `/forms/${formUri}/items`;
};

export const FormItemEdit = props => (
  <EditGuesser {...props} redirect={redirect}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source={"label"} validate={[required()]}/>
        <InputGuesser source={"name"} validate={[required()]}/>
        <InputGuesser source={"syncFieldName"}/>
        <div fullWidth>
          <h6>Zoho Field names (case sensitive)</h6>
          <p>FirstName, LastName, FullName, Email, Phone, City, Street, ZipCode, Company, NoOfEmployees, LeadSource,
            Owner, Description, Rating, Website, Twitter, Salutation, LeadStatus, Industry, SkypeID, Designation,
            Mobile, Fax, AnnualRevenue, SecondaryEmail</p>
        </div>
        <SelectInput source="type" choices={formItemTypes} validate={[required()]}
        />
        <InputGuesser source={"value"}/>
        <InputGuesser source={"selected"}/>
        <InputGuesser source={"placeHolder"}/>
        <InputGuesser source={"maxLength"}/>
        <InputGuesser source={"minLength"}/>
        <InputGuesser source={"multiple"}/>
        <InputGuesser source={"expanded"}/>
        <InputGuesser source={"required"}/>
        <InputGuesser source={"order"} validate={[required()]}/>
        <InputGuesser source={"enabled"}/>
        <ReferenceInput
          allowEmpty={true}
          source="form"
          reference="forms"
          label="Form"
          filterToQuery={searchText => ({name: searchText})}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput
          source="formStep"
          reference="form_steps"
          label="FormStep"
          filterToQuery={searchText => ({title: searchText})}>
          <AutocompleteInput optionText="title"/>
        </ReferenceInput>
      </Tab>
      <FormDataConsumer>
        {({formData, ...rest}) => formData.type === 'choice' &&
          <Tab label="Choices" path="formChoices" {...rest}>
            <ReferenceManyField
              addLabel={false}
              reference="form_choices"
              target="formChoices">
              <Datagrid>
                <TextField source="key"/>
                <TextField source="value"/>
                <ShowButton/>
                <EditButton/>
              </Datagrid>
            </ReferenceManyField>
            <AddFormChoiceButton/>
          </Tab>
        }
      </FormDataConsumer>
    </TabbedShowLayout>
  </EditGuesser>
);
