import {CreateGuesser, EditGuesser} from "@api-platform/admin";
import React from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  ImageInput,
  FileInput,
  FormDataConsumer,
  NumberInput
} from "react-admin";
import {parse} from "query-string";
import CustomImageField from "../components/fields/CustomImageField";
import CustomVideoField from "../components/fields/CustomVideoField";

export const PlayListItemCreate = props => {
  const {playList: playListUri} = parse(props.location.search);
  const encodedPlayListUri = encodeURIComponent(playListUri);
  const redirect = playListUri ? `/play_lists/${encodedPlayListUri}/items` : false;

  return (
    <CreateGuesser {...props} redirect={redirect} initialValues={{ playList: playListUri}}>
      <ImageInput source="image" label="Image" accept="image/*"
                  placeholder={<p>Drop your file here</p>}>
        <CustomImageField source="contentUrl"/>
      </ImageInput>

      <NumberInput source={"duration"}/>

      <FileInput source="video" label="Video" accept="video/*"
                 placeholder={<p>Drop your file here</p>}>
        <CustomVideoField source="contentUrl"/>
      </FileInput>

      <ReferenceInput
        allowEmpty={true}
        source="playList"
        reference="play_lists"
        label="Play List"
        filterToQuery={searchText => ({name: searchText})}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="project"
        reference="projects"
        label="Project"
        filterToQuery={searchText => ({name: searchText})}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </CreateGuesser>
  );
}
export const PlayListItemEdit = props => (
  <EditGuesser {...props}>
    <ImageInput source="image" label="Image" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <NumberInput source={"duration"}/>

    <FileInput source="video" label="Video" accept="video/*"
               placeholder={<p>Drop your file here</p>}>
      <CustomVideoField source="contentUrl"/>
    </FileInput>

    <ReferenceInput
      allowEmpty={true}
      source="playList"
      reference="play_lists"
      label="Play List"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </EditGuesser>
);
