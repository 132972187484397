import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {
  ReferenceInput, AutocompleteInput, TabbedShowLayout,
  Tab,
  Datagrid,
  ReferenceManyField,
  TextField,
  ShowButton,
  EditButton,
  required
} from "react-admin";
import AddCategoryButton from "./addCategoryButton";

export const QuizList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"}/>
  </ListGuesser>
);

export const QuizCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="name"/>

    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </CreateGuesser>
);

export const QuizEdit = props => (
  <EditGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source="name"/>

        <ReferenceInput
          allowEmpty={true}
          source="project"
          reference="projects"
          label="Project"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      </Tab>
      <Tab label="Categories" path="categories">
        <ReferenceManyField
          addLabel={false}
          reference="quiz_question_categories"
          target="quiz">
          <Datagrid>
            <TextField source="name"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddCategoryButton/>
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);

