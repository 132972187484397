import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  required,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import {parse} from "query-string";

export const ConfiguratorItemList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"position"}/>
  </ListGuesser>
);

export const ConfiguratorItemCreate = props => {
  const {configuratorConfig: configuratorConfigUri} = parse(props.location.search);
  const encodedConfiguratorConfigUri = encodeURIComponent(configuratorConfigUri);
  const redirect = configuratorConfigUri ? `/configurator_configs/${encodedConfiguratorConfigUri}/items` : false;

  return (
    <CreateGuesser {...props} redirect={redirect} initialValues={{ configuratorConfig: configuratorConfigUri}}>
      <InputGuesser source={"name"} validate={[required()]}/>
      <InputGuesser source={"price"} />
      <InputGuesser source={"position"} />
      <RichTextInput source={"equipments"} fullWidth={true} multiline />

      <ReferenceInput
        allowEmpty={true}
        source="product"
        reference="products"
        label="Product"
        filterToQuery={searchText => ({name: searchText})}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="productVersion"
        reference="product_versions"
        label="Product version"
        filterToQuery={searchText => ({name: searchText})}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="configuratorConfig"
        reference="configurator_configs"
        label="Configurator Config"
        filterToQuery={searchText => ({name: searchText})}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>

      <ReferenceInput
        allowEmpty={true}
        source="project"
        reference="projects"
        label="Project"
        filterToQuery={searchText => ({name: searchText})}
        validate={[required()]}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </CreateGuesser>
  );
}
export const ConfiguratorItemEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source={"name"} validate={[required()]}/>
    <InputGuesser source={"price"} />
    <InputGuesser source={"position"} />
    <RichTextInput source={"equipments"} fullWidth={true} multiline />

    <ReferenceInput
      allowEmpty={true}
      source="configuratorConfig"
      reference="configurator_configs"
      label="Configurator Config"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="product"
      reference="products"
      label="Product"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="productVersion"
      reference="product_versions"
      label="Product version"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="configuratorConfig"
      reference="configurator_configs"
      label="Configurator Config"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </EditGuesser>
);
