import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  ImageInput,
  required,
  minLength,
  Tab,
  ReferenceManyField,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  TabbedShowLayout,
} from "react-admin";
import CustomImageField from "../components/fields/CustomImageField";
import AddConfigurationItemButton from "./addConfigurationItemButton";

export const PhotoBoothConfigList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"standalone"}/>
    <FieldGuesser source={"printEnabled"}/>
    <FieldGuesser source={"sendViaEmailEnabled"}/>
    <FieldGuesser source={"devices"}/>
    <FieldGuesser source={"form"}/>
    <FieldGuesser source={"project"}/>
  </ListGuesser>
);

export const PhotoBoothConfigCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} validate={[required()]}/>
    <InputGuesser source={"standalone"}/>
    <InputGuesser source={"printEnabled"}/>
    <InputGuesser source={"sendViaEmailEnabled"}/>
    <InputGuesser source={"scanMessage"}/>

    <ImageInput source="scanIcon" label="ScanIcon" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"brandingEnabled"}/>

    <ImageInput source="brandingBottomLeftLogo" label="brandingBottomLeftLogo" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"brandingBottomLeftLogoBottomMargin"} defaultValue={0}/>
    <InputGuesser source={"brandingBottomLeftLogoLeftMargin"} defaultValue={0}/>

    <ImageInput source="brandingBottomRightLogo" label="brandingBottomRightLogo" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"brandingBottomRightLogoBottomMargin"} defaultValue={0}/>
    <InputGuesser source={"brandingBottomRightLogoRightMargin"} defaultValue={0}/>

    <ImageInput source="brandingTopLeftLogo" label="brandingTopLeftLogo" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"brandingTopLeftLogoTopMargin"} defaultValue={0}/>
    <InputGuesser source={"brandingTopLeftLogoLeftMargin"} defaultValue={0}/>

    <ImageInput source="brandingTopRightLogo" label="brandingTopRightLogo" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <InputGuesser source={"brandingTopRightLogoTopMargin"} defaultValue={0}/>
    <InputGuesser source={"brandingTopRightLogoRightMargin"} defaultValue={0}/>

    <InputGuesser source={"takePictureMessage"}/>
    <InputGuesser source={"sendViaEmailMessage"}/>
    <InputGuesser source={"sendViaEmailButtonLabel"}/>
    <InputGuesser source={"printButtonLabel"}/>
    <InputGuesser source={"restartButtonLabel"}/>
    <InputGuesser source={"cancelButtonLabel"}/>
    <InputGuesser source={"backButtonLabel"}/>
    <InputGuesser source={"buttonTextColor"} validate={[minLength(4)]}/>
    <InputGuesser source={"buttonBackgroundColor"} validate={[minLength(4)]}/>
    <InputGuesser source={"mainBackgroundColor"} validate={[minLength(4)]}/>
    <InputGuesser source={"mainTextColor"} validate={[minLength(4)]}/>

    <InputGuesser source={"cssStyle"}/>

    <ReferenceInput
      allowEmpty={true}
      source="form"
      reference="forms"
      label="Form"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceArrayInput
      source="devices"
      reference="devices"
      label="Devices"
      filterToQuery={searchText => ({name: searchText})}
      filter={{ 'application.slug': 'photo-booth'}}
    >
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>

    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </CreateGuesser>
);
export const PhotoBoothConfigEdit = props => (
  <EditGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source={"name"} validate={[required()]}/>
        <InputGuesser source={"standalone"}/>
        <InputGuesser source={"printEnabled"}/>
        <InputGuesser source={"sendViaEmailEnabled"}/>
        <InputGuesser source={"scanMessage"}/>

        <ImageInput source="scanIcon" label="ScanIcon" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"brandingEnabled"}/>

        <ImageInput source="brandingBottomLeftLogo" label="brandingBottomLeftLogo" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"brandingBottomLeftLogoBottomMargin"} defaultValue={0}/>
        <InputGuesser source={"brandingBottomLeftLogoLeftMargin"} defaultValue={0}/>

        <ImageInput source="brandingBottomRightLogo" label="brandingBottomRightLogo" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"brandingBottomRightLogoBottomMargin"} defaultValue={0}/>
        <InputGuesser source={"brandingBottomRightLogoRightMargin"} defaultValue={0}/>

        <ImageInput source="brandingTopLeftLogo" label="brandingTopLeftLogo" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"brandingTopLeftLogoTopMargin"} defaultValue={0}/>
        <InputGuesser source={"brandingTopLeftLogoLeftMargin"} defaultValue={0}/>

        <ImageInput source="brandingTopRightLogo" label="brandingTopRightLogo" accept="image/*"
                    placeholder={<p>Drop your file here</p>}>
          <CustomImageField source="contentUrl"/>
        </ImageInput>

        <InputGuesser source={"brandingTopRightLogoTopMargin"} defaultValue={0}/>
        <InputGuesser source={"brandingTopRightLogoRightMargin"} defaultValue={0}/>

        <InputGuesser source={"takePictureMessage"}/>
        <InputGuesser source={"sendViaEmailMessage"}/>
        <InputGuesser source={"sendViaEmailButtonLabel"}/>
        <InputGuesser source={"printButtonLabel"}/>
        <InputGuesser source={"restartButtonLabel"}/>
        <InputGuesser source={"cancelButtonLabel"}/>
        <InputGuesser source={"backButtonLabel"}/>
        <InputGuesser source={"buttonTextColor"} validate={[minLength(4)]}/>
        <InputGuesser source={"buttonBackgroundColor"} validate={[minLength(4)]}/>
        <InputGuesser source={"mainBackgroundColor"} validate={[minLength(4)]}/>
        <InputGuesser source={"mainTextColor"} validate={[minLength(4)]}/>

        <InputGuesser source={"cssStyle"}/>

        <ReferenceInput
          allowEmpty={true}
          source="form"
          reference="forms"
          label="Form"
          filterToQuery={searchText => ({name: searchText})}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>

        <ReferenceArrayInput
          source="devices"
          reference="devices"
          label="Devices"
          filterToQuery={searchText => ({name: searchText})}
          filter={{ 'application.slug': 'photo-booth'}}
        >
          <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput>

        <ReferenceInput
          allowEmpty={true}
          source="project"
          reference="projects"
          label="Project"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      </Tab>
      <Tab label="Configurations" path="configurations">
        <ReferenceManyField
          addLabel={false}
          reference="configuration_item_values"
          target="photoBoothConfig">
          <Datagrid>
            <TextField source="name"/>
            <TextField source="type"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddConfigurationItemButton context={'photoBoothConfig'}/>
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
