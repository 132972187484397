import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  required,
  Tab,
  ReferenceManyField,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  TabbedShowLayout,
} from "react-admin";
import AddHeroProductButton from "./addHeroProductButton";

export const DeskConfigurationList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"brand"}/>
    <FieldGuesser source={"sessionTimeOut"}/>
    <FieldGuesser source={"isHeroProductsClickable"}/>
    <FieldGuesser source={"allowSkippingWelcomeVideoStep"}/>
    <FieldGuesser source={"controlNavigationViaPresenceSensor"}/>
    <FieldGuesser source={"enableShakeItGetIt"}/>
    <FieldGuesser source={"project"}/>
  </ListGuesser>
);

export const DeskConfigurationCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} validate={[required()]}/>

    <InputGuesser source={"videoUrl"}/>
    <InputGuesser source={"heroScreenTitle"}/>
    <InputGuesser source={"ScreenSubTitle"}/>
    <InputGuesser source={"dataUrl"}/>
    <InputGuesser source={"isHeroProductsClickable"}/>
    <InputGuesser source={"sessionTimeOut"}/>
    <InputGuesser source={"allowSkippingWelcomeVideoStep"}/>
    <InputGuesser source={"controlNavigationViaPresenceSensor"}/>
    <InputGuesser source={"enableShakeItGetIt"}/>
    <InputGuesser source={"cssStyle"} multilline fullWidth={true}/>

    <ReferenceInput
      allowEmpty={true}
      source="brand"
      reference="brands"
      label="Brand"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceArrayInput
      source="devices"
      reference="devices"
      label="Devices"
      filterToQuery={searchText => ({name: searchText})}
      filter={{'application.slug': 'lift-learn-desk'}}
    >
      <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>

    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </CreateGuesser>
);

export const DeskConfigurationEdit = props => (
  <EditGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source={"name"} validate={[required()]}/>

        <InputGuesser source={"videoUrl"}/>
        <InputGuesser source={"heroScreenTitle"}/>
        <InputGuesser source={"ScreenSubTitle"}/>
        <InputGuesser source={"dataUrl"}/>
        <InputGuesser source={"isHeroProductsClickable"}/>
        <InputGuesser source={"sessionTimeOut"}/>
        <InputGuesser source={"allowSkippingWelcomeVideoStep"}/>
        <InputGuesser source={"controlNavigationViaPresenceSensor"}/>
        <InputGuesser source={"enableShakeItGetIt"}/>
        <InputGuesser source={"cssStyle"} multilline fullWidth={true}/>

        <ReferenceInput
          allowEmpty={true}
          source="brand"
          reference="brands"
          label="Brand"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>

        <ReferenceArrayInput
          source="devices"
          reference="devices"
          label="Devices"
          filterToQuery={searchText => ({name: searchText})}
          filter={{'application.slug': 'lift-learn-desk'}}
        >
          <AutocompleteArrayInput optionText="name"/>
        </ReferenceArrayInput>

        <ReferenceInput
          allowEmpty={true}
          source="project"
          reference="projects"
          label="Project"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      </Tab>
      <Tab label="Hero Products" path="hero_products">
        <ReferenceManyField
          addLabel={false}
          reference="desk_hero_products"
          target="deskConfiguration">
          <Datagrid>
            <TextField source="code"/>
            <FieldGuesser source={"product"}/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddHeroProductButton />
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
