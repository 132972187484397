import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {
  ReferenceInput,
  AutocompleteInput,
  required
} from "react-admin";

export const CategoryList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"}/>
  </ListGuesser>
);

export const CategoryCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="name"/>

    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </CreateGuesser>
);

export const CategoryEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source="name"/>

    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </EditGuesser>
);
