import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import AddDeviceButton from "../device/addDeviceButton";

export const DeviceCircleList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"slug"}/>
  </ListGuesser>
);

export const DeviceCircleCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="name"/>

    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="brand"
      reference="brands"
      label="Brand"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </CreateGuesser>
);

export const DeviceCircleEdit = props => (
  <EditGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source="name"/>

        <ReferenceInput
          allowEmpty={true}
          source="project"
          reference="projects"
          label="Project"
          filterToQuery={searchText => ({name: searchText})}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>

        <ReferenceInput
          allowEmpty={true}
          source="brand"
          reference="brands"
          label="Brand"
          filterToQuery={searchText => ({name: searchText})}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      </Tab>
      <Tab label="Devices" path="devices">
        <ReferenceManyField
          addLabel={false}
          reference="devices"
          target="deviceCircle">
          <Datagrid>
            <TextField source="name"/>
            <TextField source="deviceUID"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddDeviceButton/>
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
