import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  Datagrid,
  ShowButton,
  EditButton,
  ReferenceManyField,
  Tab,
  TabbedShowLayout,
} from "react-admin";
import CustomMediaField from "./customMediaField";
import AddPlayListItemButton from "./addPlayListItemButton";

export const PlayListList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"project"}/>
  </ListGuesser>
);

export const PlayListCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} validate={[required()]}/>

    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
      validate={[required()]}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
  </CreateGuesser>
);
export const PlayListEdit = props => (
  <EditGuesser {...props}>
    <TabbedShowLayout fullWidth={true}>
      <Tab label="Summary">
        <InputGuesser source={"name"} validate={[required()]}/>

        <ReferenceInput
          allowEmpty={true}
          source="project"
          reference="projects"
          label="Project"
          filterToQuery={searchText => ({name: searchText})}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      </Tab>
      <Tab label="Items" path="items">
        <ReferenceManyField
          addLabel={false}
          reference="play_list_items"
          target="playList">
          <Datagrid>
            <CustomMediaField />
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddPlayListItemButton />
      </Tab>
    </TabbedShowLayout>
  </EditGuesser>
);
