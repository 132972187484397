import {CreateGuesser, EditGuesser, InputGuesser, ListGuesser, FieldGuesser} from "@api-platform/admin";
import React from "react";
import {
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  ImageInput,
  FileInput,
  SelectInput,
  DateField,
  usePermissions,
  Show,
  SimpleShowLayout,
  TextField,
  BulkDeleteButton, Labeled
} from "react-admin";
import CustomImageField from "./fields/CustomImageField";
import CustomVideoField from "./fields/CustomVideoField";
import HasMediaTypeField from "./fields/HasMediaTypeField";
import CustomDocumentField from "./fields/CustomDocumentField";
import {Fragment} from 'react';

import {Card as MuiCard, CardContent, withStyles} from '@material-ui/core';

import {LatestFilter} from '../filters/datefilters';
import isGranted from "../security";
import VideoField from "./fields/VideoField";

const Card = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1, // display on the left rather than on the right of the list
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const FilterSidebar = () => (
  <Card>
    <CardContent>
      <LatestFilter/>
    </CardContent>
  </Card>
);

const PostBulkActionButtons = props => (
  <Fragment>
    {/* default bulk delete action */}
    <BulkDeleteButton {...props} />
  </Fragment>
);

export const PostList = props => {
  const {loaded, permissions} = usePermissions();

  return (
    <ListGuesser aside={<FilterSidebar/>} bulkActionButtons={loaded === true && isGranted('ROLE_ADMIN', permissions) ?
      <PostBulkActionButtons/> : false} {...props}>
      <TextField source={"title"}/>
      <HasMediaTypeField label="Media Type"/>
      <DateField source={"createdAt"} showTime={true}/>
    </ListGuesser>
  );
}

export const PostShow = props => {
  console.log('props:', props)
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id"/>
        <TextField source="title"/>
        <Labeled label={"Video"}>
          <VideoField source={"video.contentUrl"}/>
        </Labeled>
        <DateField source="createdAt" showTime={true}/>
        <FieldGuesser source={"person"} linkType="show"/>
      </SimpleShowLayout>
    </Show>
  );
}

export const PostCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"title"}/>
    <TextInput multiline source={"description"}/>
    <InputGuesser source={"link"} fullWidth/>
    <InputGuesser source={"contactLink"} fullWidth/>

    <ImageInput source="image" label="Image" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <FileInput source="video" label="Video" accept="video/*"
               placeholder={<p>Drop your file here</p>}>
      <CustomVideoField source="contentUrl"/>
    </FileInput>

    <FileInput source="downloadableDocument" label="Downloadable Document" placeholder={<p>Drop your file here</p>}>
      <CustomDocumentField source="name"/>
    </FileInput>

    <InputGuesser source={"downloadableDocumentDownloadButtonLabel"}/>
    <InputGuesser source={"downloadableDocumentSendViaEmailButtonLabel"}/>

    <InputGuesser source={"enableTestDriveRequest"}/>

    <ReferenceInput
      allowEmpty={true}
      source="brand"
      reference="brands"
      label="Brand"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="product"
      reference="products"
      label="Product"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="productVersion"
      reference="product_versions"
      label="Product Version"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="application"
      reference="applications"
      label="Application"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="device"
      reference="devices"
      label="Device"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <SelectInput source="status" choices={[
      {id: 'draft', name: 'Draft'},
      {id: 'published', name: 'Published'},
      {id: 'archived', name: 'Archived'},
    ]}/>
  </CreateGuesser>
);
export const PostEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source={"title"}/>
    <TextInput multiline source={"description"} fullWidth/>
    <InputGuesser source={"link"} fullWidth/>
    <InputGuesser source={"contactLink"} fullWidth/>

    <ImageInput source="image" label="Image" accept="image/*"
                placeholder={<p>Drop your file here</p>}>
      <CustomImageField source="contentUrl"/>
    </ImageInput>

    <FileInput source="video" label="Video" accept="video/*"
               placeholder={<p>Drop your file here</p>}>
      <CustomVideoField source="contentUrl"/>
    </FileInput>

    <FileInput source="downloadableDocument" label="Downloadable Document" placeholder={<p>Drop your file here</p>}>
      <CustomDocumentField source="name"/>
    </FileInput>

    <InputGuesser source={"downloadableDocumentDownloadButtonLabel"}/>
    <InputGuesser source={"downloadableDocumentSendViaEmailButtonLabel"}/>

    <InputGuesser source={"enableTestDriveRequest"}/>

    <ReferenceInput
      allowEmpty={true}
      source="brand"
      reference="brands"
      label="Brand"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="product"
      reference="products"
      label="Product"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="productVersion"
      reference="product_versions"
      label="Product Version"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="project"
      reference="projects"
      label="Project"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="application"
      reference="applications"
      label="Application"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <ReferenceInput
      allowEmpty={true}
      source="device"
      reference="devices"
      label="Device"
      filterToQuery={searchText => ({name: searchText})}
    >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>

    <SelectInput source="status" choices={[
      {id: 'draft', name: 'Draft'},
      {id: 'published', name: 'Published'},
      {id: 'archived', name: 'Archived'},
    ]}/>
  </EditGuesser>
);
