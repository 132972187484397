import * as React from 'react';
import { Box, Button } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import SaveIcon from '@mui/icons-material/Save';
import ContactlessIcon from '@mui/icons-material/Contactless';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { BarChart } from "./Charts/BarChart";
import useFetch from './../useFetch';
import { Loading } from 'react-admin';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";


export default () => {

    const [visitStatsByDevicebyMinute, setVisitStatsByDevicebyMinute] = React.useState(null);
    const [visitStatsByBrandbyMinute, setVisitStatsByBrandbyMinute] = React.useState(null);



    let { data: visitStatsByDevice } = useFetch('visit_session_stats?groupBy=device');
    let { data: visitStatsByBrand } = useFetch('visit_session_stats?groupBy=brand');

    React.useEffect(() => {
        if (visitStatsByDevice) {
            const items = []
            for (let item of visitStatsByDevice) {
                items.push({ ...item, totalDuration: Math.round(item.totalDuration / 60) });
            }
            setVisitStatsByDevicebyMinute(items)
        }
    }, [visitStatsByDevice]);
    React.useEffect(() => {
        if (visitStatsByBrand) {
            const items = []
            for (let item of visitStatsByBrand) {
                items.push({ ...item, totalDuration: Math.round(item.totalDuration / 60) });
            }
            setVisitStatsByBrandbyMinute(items)
        }
    }, [visitStatsByBrand]);

    const { data: visitStats } = useFetch('visit_session_stats');
    const { data: actionStats } = useFetch('action_session_stats');
    const { data: actionStatsByDevice } = useFetch('action_session_stats?groupBy=device');
    const { data: actionStatsByProductRange } = useFetch('action_session_stats?groupBy=productRange');
    const { data: actionStatsByProduct } = useFetch('action_session_stats?groupBy=product');
    const { data: actionStatsByBrand } = useFetch('action_session_stats?groupBy=brand');


    const container = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const exportPDFWithMethod = () => {
        let element = container.current || document.body;
        setLoading(true);
        savePDF(element, {
            paperSize: "auto",
            margin: 40,
            fileName: `Report for ${new Date().getFullYear()}`,

        }, () => {
            setLoading(false);
            console.log("PDF exported");
        }
        );
    };

    return (
        <>
            {
                actionStatsByDevice && visitStats && actionStatsByProduct && actionStatsByBrand && actionStatsByProductRange ? (

                    <div style={{ padding: "20px" }}><div style={{ width: "100%", display: "flex", justifyContent: "flex-end", paddingLeft: "20px" }}>

                        {!loading ?
                            (

                                <Button
                                    variant="contained"
                                    onClick={exportPDFWithMethod}
                                    disabled={loading}
                                    startIcon={<SaveIcon />}
                                    style={{ marginRight: '28px', backgroundColor: '#00bcd4', color: '#fff' }}
                                >
                                    Export as PDF
                                </Button>
                            ) :
                            (

                                <LoadingButton
                                    loading
                                    loadingPosition="start"
                                    variant="outlined"
                                    startIcon={<SaveIcon />}
                                    style={{ marginRight: '28px', borderColor: '#000', color: '#000' }}
                                >
                                    Generating PDF file
                                </LoadingButton>
                            )}
                    </div><div ref={container}>

                            <div >
                                <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" style={{ gap: "2em", padding: "10px" }}>

                                    <Card gridColumn="span 1">
                                        <CardContent>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                <RemoveRedEyeIcon />
                                            </Typography>
                                            <Typography variant="h7" component="div">
                                                Total des visites
                                            </Typography>
                                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            </Typography>
                                            <Typography variant="h4">
                                                {visitStats && visitStats[0]['visitCount']}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                    <Card gridColumn="span 1">
                                        <CardContent>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                <TimelapseIcon />
                                            </Typography>
                                            <Typography variant="h7" component="div">
                                                Durée Total des visites
                                            </Typography>
                                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            </Typography>
                                            <Typography variant="h4">
                                                {visitStats && Math.round(visitStats[0]['totalDuration'] / 60)} Minutes
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                        </CardActions>
                                    </Card>
                                    <Card gridColumn="span 1">
                                        <CardContent>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                <ContactlessIcon />
                                            </Typography>
                                            <Typography variant="h7" component="div">
                                                Total des Interactions
                                            </Typography>
                                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            </Typography>
                                            <Typography variant="h4">
                                                {actionStats && actionStats[0]['count']}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                        </CardActions>
                                    </Card>
                                </Box>
                                <Box display="flex" mt="2em">
                                    <Box flex="3" mr="1em">
                                        <Box display="flex" alignItems="center">
                                            <h3>
                                                Interaction par produit
                                            </h3>
                                        </Box>
                                        {actionStatsByProduct &&
                                            <BarChart data={actionStatsByProduct} keys={['views', 'downloads', 'retrieves', 'receiveViaEmails', 'nfcViews']} indexedBy={'productName'} />}
                                    </Box>
                                </Box>

                                <Box display="flex" mt="2em">
                                    <Box flex="3" mr="1em">
                                        <Box display="flex" alignItems="center">
                                            <h3>
                                                Interaction par appareil
                                            </h3>
                                        </Box>
                                        {actionStatsByDevice &&
                                            <BarChart data={actionStatsByDevice} keys={['views', 'downloads', 'retrieves', 'receiveViaEmails', 'nfcViews']} indexedBy={'deviceName'} />}
                                    </Box>
                                </Box>

                                <Box flex="3" mr="1em">
                                    <Box display="flex" alignItems="center">
                                        <h3>
                                            Interaction par gamme
                                        </h3>
                                    </Box>
                                    {actionStatsByProductRange &&
                                        <BarChart data={actionStatsByProductRange} keys={['views', 'downloads', 'retrieves', 'receiveViaEmails', 'nfcViews']} indexedBy={'productRangeName'} />}
                                </Box>
                                <Box className="page-break" flex="3" mr="1em">
                                    <Box display="flex" alignItems="center">
                                        <h3>
                                            Interaction par marque
                                        </h3>
                                    </Box>
                                    {actionStatsByBrand &&
                                        <BarChart data={actionStatsByBrand} keys={['views', 'downloads', 'retrieves', 'receiveViaEmails', 'nfcViews']} indexedBy={'brandName'} />}
                                </Box>
                                <Box display="flex" mt="2em">
                                    <Box flex="3" mr="1em">
                                        <Box display="flex" alignItems="center">
                                            <h3>
                                                Visites par marque
                                            </h3>
                                        </Box>
                                        {visitStatsByBrandbyMinute &&
                                            <BarChart data={visitStatsByBrandbyMinute} keys={['visitCount', 'totalDuration']} indexedBy={'brandName'} />}
                                    </Box>
                                </Box>
                                <Box display="flex" mt="2em">
                                    <Box flex="3" mr="1em">
                                        <Box display="flex" alignItems="center">
                                            <h3>
                                                Visites par device
                                            </h3>
                                        </Box>
                                        {visitStatsByDevicebyMinute &&
                                            <BarChart data={visitStatsByDevicebyMinute} keys={['visitCount', 'totalDuration']} indexedBy={'deviceName'} />}
                                    </Box>
                                </Box>
                                <Box display="flex" mt="2em">

                                    <Box flex="3" mr="1em">
                                        <Box display="flex" alignItems="center">
                                            <h3>
                                                Total des visites par gamme
                                            </h3>
                                        </Box>
                                        {actionStatsByProductRange &&
                                            <BarChart data={actionStatsByProductRange} keys={['count']} indexedBy={'productRangeName'} />}
                                    </Box>
                                </Box>
                                <Box flex="1" height={500}>
                                    <Box display="flex" alignItems="center">
                                        <h3>
                                            Total des visites par produit
                                        </h3>
                                    </Box>
                                    {actionStatsByProduct &&
                                        <BarChart data={actionStatsByProduct} keys={['count']} indexedBy={'productName'} />}
                                </Box>
                                <Box display="flex" mt="2em">
                                    <Box flex="3" mr="1em">
                                        <Box display="flex" alignItems="center">
                                            <h3>
                                                Total interactions par Device
                                            </h3>
                                        </Box>
                                        {actionStatsByDevice &&
                                            <BarChart data={actionStatsByDevice} keys={['count']} indexedBy={'deviceName'} />}
                                    </Box>
                                </Box>
                            </div>
                        </div></div>
                ) :
                    <Loading loadingPrimary="loading" loadingSecondary="Charts is loading, just a moment please." />

            }
        </>
    );
};