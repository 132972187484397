import * as React from 'react';
import {MenuItemLink, usePermissions} from 'react-admin';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PostIcon from '@material-ui/icons/Book';
import UserIcon from '@material-ui/icons/Group';
import ImageIcon from '@material-ui/icons/Image';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import DescriptionIcon from '@material-ui/icons/Description';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AppsIcon from '@material-ui/icons/Apps';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import DevicesIcon from '@material-ui/icons/Devices';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PeopleIcon from '@material-ui/icons/People';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import BusinessIcon from '@material-ui/icons/Business';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import CompareIcon from '@material-ui/icons/Compare';
import DetailsIcon from '@material-ui/icons/Details';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import TuneIcon from '@material-ui/icons/Tune';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import isGranted from "./security";
import QrCodeIcon from '@mui/icons-material/QrCode';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    rootIcon: {
        minWidth: 40,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export const Menu = () => {
    const {loaded, permissions} = usePermissions();
    const classes = useStyles();
    const [deviceOpen, setDeviceOpen] = React.useState(false);
    const [userOpen, setUserOpen] = React.useState(false);
    const [productOpen, setProductOpen] = React.useState(false);
    const [mediaOpen, setMediaOpen] = React.useState(false);
    const [settingOpen, setSettingOpen] = React.useState(false);
    return loaded ? (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}>
            <MenuItemLink to="/" primaryText="Dashboard" leftIcon={<DashboardIcon/>}/>

            {isGranted('ROLE_SUPER_ADMIN', permissions) && [
                <MenuItemLink to="/accounts" primaryText="Accounts" leftIcon={<AccountBalanceIcon/>}/>,
            ]}
            {isGranted('ROLE_ADMIN', permissions) && [
                <MenuItemLink to={"/projects"} primaryText={"Projects"} leftIcon={<BusinessIcon/>}/>,

                <ListItem button onClick={() => setUserOpen(!userOpen)}>
                    <ListItemIcon className={classes.rootIcon}>
                        <PeopleOutlineIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Users"/>
                    {userOpen ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>,

                <Collapse in={userOpen} timeout="auto" unmountOnExit>
                    <MenuItemLink to={"/users"} primaryText={"Users"} leftIcon={<UserIcon/>}
                                  className={classes.nested}/>
                    <MenuItemLink to={"/api_tokens"} primaryText={"Api tokens"} leftIcon={<VpnKeyIcon/>}
                                  className={classes.nested}/>
                </Collapse>,

                isGranted('ROLE_SUPER_ADMIN', permissions) ?
                    <MenuItemLink to={"/applications"} primaryText={"Applications"} leftIcon={<AppsIcon/>}/> : null,

                <ListItem button onClick={() => setProductOpen(!productOpen)}>
                    <ListItemIcon className={classes.rootIcon}>
                        <EmojiTransportationIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Products"/>
                    {productOpen ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>,

                <Collapse in={productOpen} timeout="auto" unmountOnExit>
                    <MenuItemLink to={"/categories"} primaryText={"Categories"} leftIcon={<QrCodeIcon/>}
                                  className={classes.nested}/>
                    <MenuItemLink to={"/brands"} primaryText={"Brands"} leftIcon={<QrCodeIcon/>}
                                  className={classes.nested}/>
                    <MenuItemLink to={"/product_ranges"} primaryText={"Product Ranges"} leftIcon={<QrCodeIcon/>} className={classes.nested}/>
          <MenuItemLink to={"/products"} primaryText={"Products"} leftIcon={<QrCodeIcon/>}
                                  className={classes.nested}/>
                </Collapse>,

                <ListItem button onClick={() => setDeviceOpen(!deviceOpen)}>
                    <ListItemIcon className={classes.rootIcon}>
                        <DevicesIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Devices"/>
                    {deviceOpen ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>,

                <Collapse in={deviceOpen} timeout="auto" unmountOnExit>
                    {isGranted('ROLE_SUPER_ADMIN', permissions) && [
                        <MenuItemLink to={"/device_types"} primaryText={"Device types"} leftIcon={<DevicesOtherIcon/>}
                                      className={classes.nested}/>
                    ]}
                    <MenuItemLink to={"/device_circles"} primaryText={"Device circles"} leftIcon={<DeviceHubIcon/>}
                                  className={classes.nested}/>
                    <MenuItemLink to={"/devices"} primaryText={"Devices"} leftIcon={<DevicesIcon/>}
                                  className={classes.nested}/>
                </Collapse>
            ]}
            <MenuItemLink to={"/people"} primaryText={"People"} leftIcon={<PeopleIcon/>}/>
            <MenuItemLink to={"/posts"} primaryText={"Posts"} leftIcon={<PostIcon/>}/>
            {isGranted('ROLE_ADMIN', permissions) && [
                <MenuItemLink to={"/forms"} primaryText={"Forms"} leftIcon={<ListAltIcon/>}/>,

                <MenuItemLink to={"/quizzes"} primaryText={"Quizzes"} leftIcon={<ContactSupportIcon/>}/>,

                <MenuItemLink to={"/play_lists"} primaryText={"Play List"} leftIcon={<PlayCircleOutlineIcon/>}/>,

                <ListItem button onClick={() => setMediaOpen(!mediaOpen)}>
                    <ListItemIcon className={classes.rootIcon}>
                        <PermMediaIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Media"/>
                    {mediaOpen ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>,

                <Collapse in={mediaOpen} timeout="auto" unmountOnExit>
                    <MenuItemLink to={"/documents"} primaryText={"Documents"} leftIcon={<DescriptionIcon/>}
                                  className={classes.nested}/>
                    <MenuItemLink to={"/images"} primaryText={"Images"} leftIcon={<ImageIcon/>}
                                  className={classes.nested}/>
                    <MenuItemLink to={"/videos"} primaryText={"Videos"} leftIcon={<VideoLibraryIcon/>}
                                  className={classes.nested}/>
                </Collapse>,

                <ListItem button onClick={() => setSettingOpen(!settingOpen)}>
                    <ListItemIcon className={classes.rootIcon}>
                        <TuneIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Configs"/>
                    {settingOpen ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>,

                <Collapse in={settingOpen} timeout="auto" unmountOnExit>
                    <MenuItemLink to={"/showy_ai_configs"} primaryText={"Showy ai configs"}
                              leftIcon={<SettingsApplicationsIcon/>}
                              className={classes.nested}/>
                    <MenuItemLink to={"/ai_tools"} primaryText={"Ai Tools"}
                              leftIcon={<SettingsApplicationsIcon/>}
                              className={classes.nested}/>
                    <MenuItemLink to={"/desk_configurations"} primaryText={"Desk Configurations"}
                                  leftIcon={<SettingsApplicationsIcon/>}
                                  className={classes.nested}/>
                    <MenuItemLink to={"/check_in_configs"} primaryText={"Check in"} leftIcon={<CheckCircleIcon/>}
                                  className={classes.nested}/>
                    <MenuItemLink to={"/content_wall_configs"} primaryText={"Content wall"}
                                  leftIcon={<WallpaperIcon/>} className={classes.nested}/>
                    <MenuItemLink to={"/comparator_configs"} primaryText={"Comparator"}
                                  leftIcon={<CompareIcon/>} className={classes.nested}/>
                    <MenuItemLink to={"/configurator_configs"} primaryText={"Configurator"}
                                  leftIcon={<DetailsIcon/>} className={classes.nested}/>
                    <MenuItemLink to={"/bullet_time_configs"} primaryText={"Bullet time"}
                                  leftIcon={<SlowMotionVideoIcon/>} className={classes.nested}/>
                    <MenuItemLink to={"/booth_configs"} primaryText={"Booth"} leftIcon={<PhotoCameraIcon/>}
                                  className={classes.nested}/>
                    <MenuItemLink to={"/photo_booth_configs"} primaryText={"Photo booth"}
                                  leftIcon={<PhotoCameraIcon/>} className={classes.nested}/>
                    <MenuItemLink to={"/check_out_configs"} primaryText={"Check out"}
                                  leftIcon={<DoneOutlineIcon/>} className={classes.nested}/>
                    <MenuItemLink to={"/quiz_configs"} primaryText={"Quiz"} leftIcon={<ContactSupportIcon/>}
                                  className={classes.nested}/>
                    <MenuItemLink to={"/media_player_configs"} primaryText={"Media Player"}
                                  leftIcon={<PlayCircleOutlineIcon/>}
                                  className={classes.nested}/>
                    <MenuItemLink to={"/mail_configs"} primaryText={"E-mail"} leftIcon={<AlternateEmailIcon/>}
                                  className={classes.nested}/>
                    <MenuItemLink to={"/zoho_tag_configs"} primaryText={"Zoho tag"}
                                  leftIcon={<SettingsApplicationsIcon/>}
                                  className={classes.nested}/>
                </Collapse>,
            ]}
        </List>
    ) : null;
}
