import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser} from "@api-platform/admin";
import React from "react";
import {
    AutocompleteInput,
    ReferenceInput,
    required,
    Tab,
    ReferenceManyField,
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    TabbedShowLayout,
    usePermissions
} from "react-admin";
import AddParameterButton from "./addParameterButton";
import isGranted from "../security";

export const AiToolList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source={"name"}/>
        <FieldGuesser source={"description"}/>
    </ListGuesser>
);

export const AiToolCreate = props => {
    const {loaded, permissions} = usePermissions();
    return (
        <CreateGuesser {...props}>
            <InputGuesser source={"name"} validate={[required()]}/>
            <InputGuesser source={"description"}/>
            {loaded && isGranted('ROLE_SUPER_ADMIN', permissions) && (
                <InputGuesser source={"generic"}/>
            )}

            <ReferenceInput
                allowEmpty={true}
                source="project"
                reference="projects"
                label="Project"
                filterToQuery={searchText => ({name: searchText})}
                validate={[required()]}
            >
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
        </CreateGuesser>
    )
};

export const AiToolEdit = props => {
    const {loaded, permissions} = usePermissions();
    return (
        <EditGuesser {...props}>
            <TabbedShowLayout fullWidth={true}>
                <Tab label="Summary">
                    <InputGuesser source={"name"} validate={[required()]}/>
                    <InputGuesser source={"description"}/>
                    {loaded && isGranted('ROLE_SUPER_ADMIN', permissions) && (
                        <InputGuesser source={"generic"}/>
                    )}

                    <ReferenceInput
                        allowEmpty={true}
                        source="showyAiConfig"
                        reference="showy_ai_configs"
                        label="Showy Ai Config"
                        filterToQuery={searchText => ({name: searchText})}
                        // validate={[required()]}
                    >
                        <AutocompleteInput optionText="name"/>
                    </ReferenceInput>

                    <ReferenceInput
                        allowEmpty={true}
                        source="project"
                        reference="projects"
                        label="Project"
                        filterToQuery={searchText => ({name: searchText})}
                        validate={[required()]}
                    >
                        <AutocompleteInput optionText="name"/>
                    </ReferenceInput>
                </Tab>
                <Tab label="Parameters" path="parameters">
                    <ReferenceManyField
                        addLabel={false}
                        reference="ai_tool_parameters"
                        target="tool"
                        sort={{field: 'id', order: 'ASC'}}>
                        <Datagrid>
                            <TextField source="name"/>
                            <FieldGuesser source="type"/>
                            <FieldGuesser source="optional"/>
                            <FieldGuesser source="description"/>
                            <FieldGuesser source="items"/>
                            <ShowButton/>
                            <EditButton/>
                        </Datagrid>
                    </ReferenceManyField>
                    <AddParameterButton/>
                </Tab>
            </TabbedShowLayout>
        </EditGuesser>
    )
};
